import React from "react";
import "../scss/ExpenseManagement.scss";
import Push from "../assets/images/ExpenseManagement/push.svg";
import Add from "../assets/images/ExpenseManagement/add.svg";
import Review from "../assets/images/ExpenseManagement/review.png";
import Close from "../assets/images/ExpenseManagement/close.svg";
import N01 from "../assets/images/ExpenseManagement/01.svg";
import N02 from "../assets/images/ExpenseManagement/02.svg";
import N03 from "../assets/images/ExpenseManagement/03.svg";
import Spend from "../assets/icons/ExpenseManagement/spend.svg";
import Categorization from "../assets/icons/ExpenseManagement/categorization.svg";
import Reviewed from "../assets/icons/ExpenseManagement/review.svg";
import Accounting from "../assets/icons/ExpenseManagement/accounting.svg";
import Card from "../assets/icons/ExpenseManagement/card.svg";
import Dispute from "../assets/icons/ExpenseManagement/dispute.svg";
import ExpenseFaq from "../components/Faq/ExpenseFaq";
import { Link } from "react-router-dom";
import { FiArrowDown } from "react-icons/fi";
import Scaling from "../components/Scaling";

const ExpenseManagement = () => {
  return (
    <>
    <div className="expense">
      <div className="expense__wrapper">
        <div className="expense__container">
          <div className="expense__hero">
            <h1>
              Track, manage and <br /> reconcile expenses.
            </h1>
            <p>
              From business’s purchases to paying <br /> vendors to accounting,
              expense <br />
              management is integrated right in.
            </p>

            <Link to="/" className="landingPage__link">
                        Learn More <FiArrowDown />{" "}
                      </Link>
          </div>
        </div>

        <div className="expense__hero1">
          <div>
            <p>Get real-time insight in your business expenses.</p>
          </div>
          <h1>
            Expense reporting <br /> in few clicks.
          </h1>
        </div>
      </div>

      <section className="expense__reporting">
        <div className="expense__reportingContainer">
          <div className="expense__reportingContainer__card">
            <div className="expense__reportingContainer__image" data-aos="fade-right">
              <img src={Push} alt="Push" />
            </div>

            <div data-aos="fade-left">
              <div className="expense__reportingContainer__content">
                <h2>Push notification.</h2>
                <p>Get notified for every card swipe.</p>
              </div>
              <div className="expense__numbers1">
                <img src={N01} alt="01" />
              </div>
            </div>
          </div>

          <div className="expense__reportingContainer__card">
            <div data-aos="fade-right">
              <div className="expense__numbers2">
                <img src={N02} alt="NO2" />
              </div>
              <div className="expense__reportingContainer__content">
                <h2>Add required details.</h2>
                <p>
                  Add the necessary/required information such as memo, receipts,
                  etc. of the said transaction.
                </p>
              </div>
            </div>
            <div className="expense__reportingContainer__image" data-aos="fade-left">
              <img src={Add} alt="Add" />
            </div>
          </div>

          <div className="expense__reportingContainer__card">
            <div className="expense__reportingContainer__image" data-aos="fade-right">
              <img src={Review} alt="Review" />
            </div>

            <div data-aos="fade-left">
              <div className="expense__reportingContainer__content">
                <h2>Review and sync / export transaction data.</h2>
                <p>Review the transaction, and mark it ready for export.</p>
              </div>
              <div className="expense__numbers">
                <img src={N03} alt="N03" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="expense__bg1">
        <div className="expense__bg2">
          <div className="expense__masterHero">
            <section className="expense__control">
              <div className="expense__control__wrapper">
                <h2>
                  Simple, yet effective <br /> expense management.
                </h2>
                <div className="expense__cardContainer" >
                  <div data-aos="fade-right">
                    <img src={Spend} alt="Spend" />
                    <h2>Spend notifications.</h2>
                    <p>
                      No expense goes uncategorized thanks to push notifications and weekly reminder emails.
                    </p>
                  </div>

                  <div data-aos="zoom-in">
                    <img src={Categorization} alt="Categorization" />
                    <h2>Categorization, receipt marching.</h2>
                    <p>
                      Categorize your transactions using the expense classification filds, and match your receipts, after recieving a push notification in just few clicks.
                    </p>
                  </div>

                  <div data-aos="fade-left">
                    <img src={Reviewed} alt="Review" />
                    <h2>Review data, in real-time.</h2>
                    <p>
                      Review all transactions in real-time, and mark them ready for export as a CVV file or into your accounting software.
                    </p>
                  </div>

                  <div data-aos="fade-right">
                    <img src={Accounting} alt="Accounting" />
                    <h2>Accounting Integration.</h2>
                    <p>
                      Evea matches to your chart of accounts, saving you hourd of manual data entry and reconciliation.
                    </p>
                  </div>

                  <div data-aos="zoom-in">
                    <img src={Card} alt="Card" />
                    <h2>Card Management..</h2>
                    <p>
                      Easily lock and unlock your card when neccessary.
                    </p>
                  </div>

                  <div data-aos="fade-left">
                    <img src={Dispute} alt="Dispute" />
                    <h2>Dispute resolution.</h2>
                    <p>
                      If you ever experience a fradulent transaction or a failed transaction[and still got charged for it], report it and we'll look into it immediately
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="expense__close">
              <div className="expense__closeWrapper">
                <div className="expense__closeWrapper__content" data-aos="fade-down">
                  <h2>Close your books faster. <span>Coming Soon</span> </h2>
                  <h3>(Accounting Integration)</h3>
                  <p>
                    Stop wasting hours each month balancing your books. Easily
                    sync your expense data across your accounting system (such
                    as QuickBooks, etc.) in real-time, keeping your books tidy
                    with smart categorization of expenses, automated
                    receipt-matching, transaction reconciliation and automatic
                    GL mapping, thereby saving you hours of accounting
                    workloads.
                  </p>
                </div>

                <div>
                  <img src={Close} alt="close" data-aos="fade-up" />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div className="businessCard__faq">
        <div className="businessCard__faqContainer">
          <div className="businessCard__faq__topic">
            <p>Frequently asked questions</p>
            <div>
              <p>
                Having trouble finding what you’re looking for? Contact our team
                for immediate assistance via phone, chat, or email, or consult
                the <a href="/">Help Center</a> at any time.
              </p>
              <a href="/contact-us">Contact us</a>
            </div>
          </div>
          <ExpenseFaq />
        </div>
      </div>
    </div>
<Scaling/>
    </>
  );
};

export default ExpenseManagement;
