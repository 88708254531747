import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../components/Privacy/Sidebar'
import '../scss/Layout.scss'

const PrivacyLayout = () => {
  return (
    <div className="privacyLayout">
      <Sidebar/>
      <div className="privacyLayout__right">
        <div className="privacyLayout__rightWrapper">
        <Outlet/>
        </div>
      </div>
      
    </div>
  )
}

export default PrivacyLayout