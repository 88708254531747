import React from "react";
import PrimaryButton from "../components/PrimaryButton";
import PrimaryInput from "../components/PrimaryInput";
import "../scss/ContactUs.scss";
import { FiArrowRight, FiArrowDown } from "react-icons/fi";
import { Link } from "react-router-dom";
import Insta from "../assets/icons/ContactUs/insta.svg";
import Linked from "../assets/icons/ContactUs/linked.svg";
import Twitter from "../assets/icons/ContactUs/twitter.svg";

const ContactUs = () => {
  return (
    <div className="contact-us">
      <div className="contact-us__wrapper">
        <div className="contact-us__container">
          <h1 className="contact-us__title">Contact Us</h1>

          <div className="contact-us__info">
            <h2 className="contact-us__infoLeft">
              Talk to us, <br /> about anything
            </h2>
            <div className="contact-us__infoRigh">
              <PrimaryInput
                type="text"
                placeholder="Enter Full Name"
                label="First name, Last Name"
              />
              <PrimaryInput
                type="email"
                placeholder="Enter Email Address"
                label="Email address"
              />
              <PrimaryInput
                type="text"
                placeholder="Enter Company Name"
                label="Company Name"
              />
              <PrimaryInput
                type="phone"
                placeholder="Enter Website"
                label="Website"
              />

              <div>
                <label htmlFor="">Message</label>
                <textarea rows="8" />
              </div>

              <button>Send</button>
            </div>
          </div>

          <div className="contact-us__info">
            <h2 className="contact-us__infoLeft">Customer Support</h2>
            <div className="contact-us__infoRight">
              <p>
              Customer Support For Evea users, simply reach us via the live chat in your dashboard, 24/7, or by email.
              </p>

              <div className="contact-us__links">
                <Link to="/" className="contact-us__link">
                  Live Chat
                  <FiArrowDown />{" "}
                </Link>
                <Link to="mailto:help@evea.africa" target="_blank" className="contact-us__link">
                help@evea.africa <FiArrowRight />{" "}
                </Link>
              </div>
            </div>
          </div>

          <div className="contact-us__info">
            <h2 className="contact-us__infoLeft">Build with us</h2>
            <div className="contact-us__infoRight">
              <p>
                Interested in working for a mission-driven, innovative fintech
                startup that is changing the way financial services are
                delivered? We want to hear from you if you are passionate about
                revolutionizing an industry, transforming lives, and being a
                part of something greater than yourself.
              </p>

              <Link to="/work-with-us" className="contact-us__link">
                See open roles <FiArrowRight />{" "}
              </Link>
            </div>
          </div>

          {/* <div className="contact-us__info">
            <h2 className="contact-us__infoLeft">Office</h2>
            <div className="contact-us__infoRight">
              <p>
                23, Kola Amodu Cresent, <br />
                Magodo Phase 2, Lagos, <br />
                Nigeria, Africa.
              </p>
            </div>
          </div> */}

          <div className="contact-us__info">
            <h2 className="contact-us__infoLeft">Social Channels</h2>
            <div className="contact-us__infoIcons">
              <img src={Insta} alt="" />
              <img src={Linked} alt="" />
              <img src={Twitter} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="contact-us__getStarted">
        <div className="contact-us__getStarted__container">
          <div className="contact-us__getStarted__wrapper">
            <h1>Make your aspirations a reality.</h1>
            <p>
              Get a corporate card with no personal guarantee, with business
              management solutions in one place.
            </p>
            <PrimaryButton text="Get Started" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
