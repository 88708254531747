import React from "react";

const Privacy = () => {
  return (
    <div className="privacyDashboard">
      <h1>Evea Privacy Policy</h1>
      <b>Updated April, 12 2022</b> <br/>
      <b>Introduction</b>

      <p>
        Evea Limited (“Evea”, “we” “our” “us”) provide financial products and
        services to emerging businesses. We recognize that by using Evea, you
        are entrusting us with your data and personal information, including the
        personal information of anyone linked with your firm, such as employees,
        contractors, shareholders, or beneficial owners.
      </p>

      <p>
        This Privacy Policy governs our website (www.evea.africa), apps
        ("Products"), emails and ads, as well as any other online or offline
        location where we make this Privacy Policy available to you.
      </p>

      <p>
        Evea values your privacy and the security of the information you share
        with us. This Privacy Policy explains how we collect, use, disclose and
        safeguard the information we obtain from you in relation to your use of
        our website, products or services. It applies to our website and all
        related sites, applications, services and tools regardless of how you
        access or use them.{" "}
      </p>

      <p>
        By using our website, products, or services, you consent to the terms
        and practices contained in this Privacy Policy and you grant us the
        right to collect and process your data in accordance with the terms of
        this Policy.
      </p>

      <p>
        Our website and services are not directed to children under 18. We do
        not knowingly collect information from children under 18. If as a parent
        or guardian, you become aware that your child or ward child has provided
        us with any information without your consent, please contact us through
        the details provided in this Privacy Policy.{" "}
      </p>

      <b>THE INFORMATION WE COLLECT</b>

      <p>
        When you use our website, product or service, we may collect certain
        types of information about you including the following:
      </p>

      <b>Information you provide to us in creating an Evea Account</b>
      <p>
        To comply with our legal and regulatory duties, we must process certain
        basic information about you, your company, and individuals linked with
        your company in order to create an Evea Account:
      </p>

      <ul className="privacyDashboard__list">
        <li>Your full legal name</li>
        <li>Your email</li>
        <li>Your phone number</li>
        <li>Your business address</li>
        <li>Your title or role on your team</li>
        <li>Your date of birth</li>
        <li>
          Your valid national identity (either Int. passport, voter’s card, NIN,
          driver’s license or other national ID)
        </li>
        <li>
          Your company details: businesslegal name, address, copies of the
          company’s corporate documents, and Tax Identification Number (TIN)
        </li>
        <li>Company’s monthly expense information.</li>
        <li>
          Information about the individual on the leadership team or individuals
          who have an ownership interest in your company.
        </li>
        <li>Bank account information and Bank verification number (BVN)</li>
        <li>
          Personal information provided to us from third-party companies we
          partner with to offer services to you (see below).
        </li>
      </ul>

      <b>
        Information we collect automatically when you visit our website or use
        our product
      </b>

      <p>
        This includes but is not limited to your IP address, browser type,
        mobile device ID, device type, operating system version, connection
        information, mobile network information, location derived from
        GPS-enabled services, information based on your usage of the service
        such as time, date and duration of your use, referral URLs, search terms
        and search history, browsing history, purchase history and advertisement
        interactions.
      </p>

      <b>Information we collect when you interact with Evea teams</b>

      <p>
        When you phone, chat, email, or communicate with members of the Evea
        team (such as Customer Service, Sales, or Marketing), we may collect the
        following personal information about you, but not limited to:{" "}
      </p>

      <ul>
        <li>Your name</li>
        <li>
          Your email, phone number, or other contact information (such as phone
          number)
        </li>
        <li>
          Content of your communications, including, in some cases recorded
          calls, and survey responses.
        </li>
      </ul>

      <b>
        Information we collect when you attend an Evea event (online or offline)
      </b>

      <p>
        When you register and attend an Evea webinar or event, we may collect
        certain personal information about you:
      </p>

      <ul className="privacyDashboard__list">
        <li>Your name</li>
        <li>Your email or other contact information (such as phone number)</li>
        <li>Registration date and time</li>
        <li>
          Webinar attendance information, when you joined a webinar, when you
          left, if you were paying attention, and what country you joined from.
        </li>
      </ul>

      <b>Information we obtain from 3rd Parties</b>

      <p>
        We may retrieve additional personal information about you from third
        parties and other identification/verification services such as your
        financial institution and payment processor. With your consent, we may
        also collect additional Personal Information in other ways including
        emails, surveys, and other forms of communication. Once you begin using
        our services through your Evea account we will keep records of your
        transactions and collect information of your other activities related to
        our services. We will not share or disclose your Personal Information
        with a third party without your consent except as may be required for
        the purpose of providing you with our services or under applicable
        legislation.
      </p>

      <p>
        In providing you with the services, we may rely on third-party servers
        located in foreign jurisdictions from time to time, which as a result,
        may require the transfer or maintenance of your personally identifiable
        information on computers or servers in foreign jurisdictions. We will
        endeavour to ensure that such foreign jurisdictions have data protection
        legislation that is no less than the existing data protection
        regulations in force in Nigeria and your personally identifiable
        information is treated in a safe and secure manner.
      </p>

      <b>WHY WE COLLECT YOUR INFORMATION</b>

      <p>
        We collect your information to ensure your easy and seamless access to
        our services. We use the information we collect for the following
        purposes:
      </p>

      <ul className="privacyDashboard__list">
        <li>
          To enable us to provide you with a personalized experience of our
          product and services.
        </li>
        <li>
          To communicate with you and provide you with information on our
          Services, such marketing content, newsletters and service update.
          However, we will provide you with an option to unsubscribe if you do
          not want to hear from us
        </li>
        <li>To provide support services to you.</li>
        <li> To process your orders and requests.</li>
        <li>
          To analyse and understand your use of our products and services.
        </li>
        <li>To protect against illegal, malicious, and fraudulent activity.</li>
        <li>
          To analyse and improve the quality of our services and offerings.
        </li>
        <li>
          To facilitate your interactions with our social media platforms.
        </li>
        <li>
          To analyse and learn about our users’ demographics, interests, and
          behaviour.
        </li>
        <li>To identify and repair errors and bugs on our platforms.</li>
        <li>
          To facilitate dissemination of information about our services and our
          partners.
        </li>
      </ul>

      <b>SHARING YOUR INFORMATION WITH THIRD PARTIES</b>

      <p>
        We may sometimes share the information we have collected from you with
        third parties. These third parties include our affiliates, subsidiaries,
        partners, third-party vendors and suppliers who render services for us
        or on our behalf and our professional advisers (such as our auditors,
        accountants, lawyers, etc.). We may share the information to facilitate
        the seamless delivery of our services or in compliance with applicable
        legal requirements and assisting law enforcement agencies by responding
        to requests for the disclosure of information in accordance with local
        laws.
      </p>

      <b>COOKIES</b>

      <p>
        We use cookies to identify you as a User and make your user experience
        easier, customise our services, content and advertising; help you ensure
        that your account security is not compromised, mitigate risk and prevent
        fraud; and to promote trust and safety on our website. Cookies allow our
        servers to remember IP addresses, date and time of visits, monitor web
        traffic and prevent fraudulent activities.
      </p>

      <p>
        Our cookies never store personal or sensitive information; they simply
        hold a unique random reference to you so that once you visit the site we
        can recognize who you are and provide certain content to you.
      </p>

      <p>
        If your browser or browser add-on permits, you have the choice to
        disable cookies on our website, however this may impact your experience
        using our website.
      </p>

      <b>GOVERNING PRINCIPLES OF DATA PROCESSING</b>

      <p>
        We process your information in accordance with the provisions of the
        Nigeria Data Protection Regulation and these principles:
      </p>

      <ul className="privacyDashboard__list">
        <li>
          Your personal data is collected and processed in accordance with
          specific, legitimate and lawful purpose consented to by you, provided
          that further processing may be done by archiving the data for public
          interest, scientific or research purposes, or statistical purposes.
        </li>
        <li>
          The data collected is adequate, accurate and without prejudice to the
          dignity of the human person.
        </li>
        <li>
          The data collected is stored only for the period within which it is
          reasonably needed.
        </li>
        <li>
          The data collected is secured against all foreseeable hazards and
          breaches such as theft, cyberattack, viral attack, dissemination,
          manipulations of any kind, damage by rain, fire or exposure to other
          natural elements.
        </li>
        <li>
          {" "}
          We owe a duty of care to you in respect of the data we have obtained
          from you.
        </li>
    
      </ul>

      <b>HOW WE SAFEGUARD YOUR INFORMATION</b>

      <p>
        In addition to the security measures we employ, you must also ensure
        that you take responsibility for the protection of your personal
        information. We will not be liable for any loss or damage caused by the
        activities of hackers and fraudsters when we have taken all necessary
        and reasonable steps to protect your information in our possession. You
        are fully responsible for safeguarding your username, password, and
        financial information.
      </p>

      <b>LINKS TO THIRD PARTY WEBSITES</b>

      <p>
        Our website, products and services may contain links to other websites
        not subject to this Privacy Policy. When you access those websites, they
        may collect your personal information in accordance with their privacy
        and data collection policies. We are not responsible for the data
        collected by these third parties and you are advised to study their
        privacy policies before you make use of their services.
      </p>

      <b>YOUR PRIVACY RIGHTS</b>

      <p>
        By providing us with your personal information, you have certain rights
        in accordance with the provisions of the Nigeria Data Protection
        Regulation which include:
      </p>

      <ul className="privacyDashboard__list">
        <li>the right to request for your personal data in our possession, </li>
        <li>the right to object to the processing of your personal data,</li>
        <li>
          the right to request rectification and modification of Personal
          Information which Evea keeps; and{" "}
        </li>
        <li>
          the right to request for the deletion of your personal data in our
          possession. To exercise any of the rights listed here, please contact
          us through the details provided below.{" "}
        </li>
      
      </ul>

      <p>
        All such requests will be reviewed and considered in accordance with the
        provisions of the applicable data protection regulations.
      </p>

      <p>
        You also have the right to opt-out of receiving the informative
        materials we send to our users by clicking the “Unsubscribe” button
        found at the bottom of such emails. You also have the right to decline
        to provide your personal Information when it is requested, however,
        certain services or all the services may be unavailable to you.
      </p>

      <b>INTERNATIONAL TRANSFER OF YOUR INFORMATION</b>

      <p>
        We may need to transfer your information in our possession to third
        parties outside Nigeria. Such transfers will only be done in accordance
        with the applicable data protection regulations. While undertaking the
        international transfer of your information, we will put security
        measures in place to reasonably protect your data during transmission.
        We shall, among other things, confirm whether the country is on the
        National Information Technology Development Agency (“NITDA”) White List
        of Countries with adequate data protection laws
      </p>

      <b>DATA RETENTION PERIOD</b>

      <p>
        We will retain your information for as long as your account is active or
        as needed to provide our services to you, comply with our legal and
        statutory obligations or verify your information with a financial
        institution.
      </p>

      <p>
        Evea is statutorily obligated to retain the data you provide us with in
        order to process transactions, ensure settlements, make refunds,
        identify fraud and in compliance with laws and regulatory guidelines
        applicable to us, our banking providers and card processors.
      </p>

      <p>
        Therefore, even after closing your Evea account, we will retain certain
        Personal Information and transaction data to comply with these
        obligations. All Personal Information shall be destroyed by Evea where
        possible. For all Personal Data and records obtained, used and stored by
        Evea, we shall perform periodical reviews of the data retained to
        confirm the accuracy, purpose, validity and requirement to retain.
      </p>

      <p>
        The length of storage of Personal Information shall, amongst other
        things, be determined by:
      </p>

      <ul className="privacyDashboard__list">
        <li>
          the contract terms agreed between Evea and the Data Subject or as long
          as it is needed for the purpose for which it was obtained; or
        </li>
        <li>
          whether the transaction or relationship has statutory implication or a
          required retention period; or
        </li>
        <li>
          whether there is an express request for deletion of Personal Data by
          the Data Subject, provided that such request will only be treated
          where the Data Subject is not under any investigation which may
          require Evea to retain such Personal Data or there is no subsisting
          contractual arrangement with the Data Subject that would require the
          processing of the Personal Data; or
        </li>
        <li>
          whether Evea has another lawful basis for retaining that information
          beyond the period for which it is necessary to serve the original
          purpose.
        </li>
       
      </ul>

      <b>CONTACTING US</b>

      <p>
        If you have any question, request or complaints, or you require further
        information not already provided in this Privacy Policy, please contact
        us by sending an email to legal@evea.africa
      </p>

      <p>
        We maintain a data breach procedure in order to deal with incidents
        concerning Personal Information or practices leading to the accidental
        or unlawful destruction, loss, alteration, unauthorized disclosure of,
        or access to, Personal Information transmitted, stored or otherwise
        processed. You may contact our DPO upon becoming aware of any breach of
        Personal Information or if your access credentials have been
        compromised, to enable us to take the necessary steps towards ensuring
        the security of your Personal Information or account.
      </p>

      <b>UPDATES TO THIS POLICY</b>

      <p>
        We reserve the right to periodically update and modify the terms of our
        Privacy Policy without notice. Changes to this Privacy Policy will take
        effect on the last updated date.
      </p>
    </div>
  );
};

export default Privacy;
