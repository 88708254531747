import React from "react";
import "../../scss/components/Faq.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Faq() {
  return (
    <div>
      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Types of Budgeting?</Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <div>
              <p>
                Within Evea's system, there are three basic categories of
                budgeting:
              </p>
              <p>
                (a) Incremental budgeting — annually changes existing budgets by
                increments based on your company's growth or loss.{" "}
              </p>
              <p>
                (a) Zero-based budgeting — entails starting each budget at zero,
                so that expenses are justified upfront, rather than later.{" "}
              </p>
              <p>
                (c) Activity-based budgeting — begins with a desired end and
                works backward to decide how to fund the actions required to
                achieve that outcome.
              </p>
            </div>
          </>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            AWhat is the significance of budgeting (spend management)?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              Budgeting is critical for managing your company's path. It’s the
              guidance/road-map you draw for your company, outlining how much
              money will be allocated to each overhead, showing you how to
              arrive to your desired destination. It makes you proactive in your
              business spending, keeping you in control.
            </p>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>
            How can a budgeting platform/system help my business?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              To simply put, helps you prevent overspending —before it occurs.
            </p>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>
            {" "}
            What is the difference between Spend management (i.e. budgeting) &
            expense management?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              Budgeting is the guidance/road-map you draw for your company,
              outlining how much money will be allocated to each overhead;
              While, Expense management is the implementation of your budget.
              Expense management is the act of documenting and tracking all of
              your company's expenses, which can then be used to guide your
              budgeting process.
            </p>
          </>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
