import React from "react";

const Prohibited = () => {
  return (
    <div className="privacyDashboard">
      <h1>Prohibited and Restricted Activities</h1>
      <b>Updated April, 12 2022</b>
      <p>
        We reserve the right to update the listings below at any time by
        releasing an updated version to our website. Immediately we post the
        updated version, it will take effect instantly. We may deny, terminate,
        or suspend your use of Evea services if we determine, in our sole
        discretion, that you are engaging in or have engaged in activities that
        are illegal, may harm others or our reputation or operations, or violate
        Evea’s or others' rights, including by engaging in any of the activities
        listed below or authorizing or assisting others to do so.
      </p>
      <h3 className="privacyDashboard__subtitlee">Prohibited Activities:</h3>
      <p>
        There are certain businesses we cannot support at Evea. Companies
        engaged in the following businesses activities will be prohibited from
        accessing its Services:
      </p>
      <ul className="privacyDashboard__list">
        <li>Gambling, betting, lotteries, etc. any games of chance.</li>
        <li>Multi-level marketing, Ponzi scheme, pyramid scheme, etc.</li>
        <li>Escort services / Prostitution, and Pornography businesses.</li>
        <li>
          Production, sale, or distribution of Hard drugs, such as marijuana,
          etc.
        </li>
        <li>
          "Gray market" goods or services. • Production, sale, or distribution
          of weapons (such as guns), ammunitions, etc.
        </li>
        <li>
          Accounts created to conceal the identity of the account's owner.
        </li>
        <li>
          Using the Services in a way that violates or is forbidden by the
          Platform Agreement.
        </li>
      </ul>
      <h3 className="privacyDashboard__subtitlee">Restricted Activities</h3>
      <p>
        Companies involved in the following businesses activities may be
        required to provide additional information or paperwork, or they may be
        ineligible for certain Services:
      </p>
      <ul className="privacyDashboard__list">
        <li>Cryptocurrency dealings </li>
        <li>
          Financial services providers, including banks/digital banks or bank
          affiliates, securities brokers, money transmitters, investment
          companies, or investment funds.
        </li>
      </ul>
      <p>
        If you have any questions or unsure about whether your business or
        activities are allowed by Evea, kindly <a href="/contact-us">contact us..</a>
      </p>
      
    </div>
  );
};

export default Prohibited;
