import React from "react";
import { Link } from "react-router-dom";
import "../scss/WorkWithUs.scss";
import { FiArrowDown } from "react-icons/fi";
import Icon from "../assets/images/WorkWithUs/icon.svg";
// import Job from "../components/Job";

const WorkWithUs = () => {
  return (
    <div className="work">
      <div className="work__hero">
        <div className="work__wrapper">
          <div className="work__container">
            <h1>We have huge dreams, come build with us.</h1>
            <p>
              Passionate about revolutionizing an industry, transforming lives,
              and being a part of something greater than oneself…? we want to
              hear from you.
            </p>
            <Link to="/" className="landingPage__link">
              See Open Roles <FiArrowDown />{" "}
            </Link>

            <div>
              <div>
                <img src={Icon} alt="" />
                <p>Flexible work hour</p>
              </div>

              <div>
                <img src={Icon} alt="" />
                <p>Unlimited PTO (for full-time)</p>
              </div>

              <div>
                <img src={Icon} alt="" />
                <p>Health Bnefits/insurance</p>
              </div>

              <div>
                <img src={Icon} alt="" />
                <p>Stock options</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="work__joblist">
        <div className="work__joblistWrapper">
          <h2>Find where you best fit in & Join the team</h2>
          <p>Positions are eligible for work anywhere in Nigeria and Africa…</p>
        </div>

        <div>
          {/* <Job/> */}
        </div>
      </div>
    </div>
  );
};

export default WorkWithUs;
