import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="privacyDashboard">
      <h1>General Disclosures</h1>‍<b>Updated April, 12 2022</b>
      <p>Evea's Terms &amp; Conditions</p>
      <p>
        These Terms and Conditions cover the process for obtaining and managing
        Cards, and payments through your Evea Account, as well as the issuing
        and usage of Cards as regulated by Issuer Terms between you and Issuers.
        These Terms &amp; Conditions are subjected to change/update at any time,
        for you will be informed/notified, and your continuing use of Cards will
        be deemed acceptance of the amended terms and conditions.
      </p>
      <p>
        The terms also authorize Evea to debit your Linked Accounts for amounts
        due for using the Services, such as Charges, Fees, Fines, and other
        transactions you undertake using Cards, as set out in these Terms and
        Conditions.
      </p>
      <p>1. Card Terms</p>
      <p>1.1 Issuer Terms</p>
      <p>
        Sudo [the Issuer] issues the Cards, as shown on the back of physical
        Cards issued to you.
      </p>
      <p>
        Your use of the Cards is governed by these Terms and Conditions, the
        Platform Agreement, and the Issuer Terms. You may use the Cards only if
        you, your Administrators/Controlling officer, and your Users (i.e.
        teams) agree to the Card Terms.
      </p>
      <p>
        Issuers may alter Issuer Terms at any time by notifying you via Evea,
        and your continued use of the Cards after receiving such notice
        indicates your acceptance of the revised Issuer Terms.
      </p>
      <p>1.2 Linked Accounts</p>
      <p>
        Financial Data from Linked Accounts [obtained from Okra] will be used to
        verify account balances and key financial information, such as spending
        patterns, income patterns, and potential fraud. These data are evaluated
        and analysed in order to deliver Services to you.
      </p>
      <p>
        At all times, you must have at least one Linked Account, and agree that
        Evea can directly debit any Linked Account for payment of amounts owed.
        Through your Evea Account (in account settings), you can modify or amend
        Linked Accounts.
      </p>
      <p>1.3 Credit Limits</p>
      <p>
        Evea determines the credit limits for the Company at its exclusive
        discretion, based on the Company's financial Data and other information
        accessible to Evea, such as available cash balance, income and spending
        patterns, E/I ratio, cashflow, nature and history of the business.
        Credit limits are dynamic and can be modified at any moment with or
        without notice to you, including temporarily increasing or decreasing
        credit limits or reducing credit limits to zero (N0.00). This dynamic
        nature is due to the regular periodic assessment of your credit limit
        based on your business's real-time financial information, and you
        acknowledge that such regular periodic adjustments are an intrinsic
        aspect of your Card.
      </p>
      <p>1.4 Requesting and Replacing Cards</p>
      <p>Requesting</p>
      <p>
        Through your Evea Account, Administrators/Controlling Officers can
        request Cards for Users. Administrators/Controlling Officers are
        restricted from requesting more than one active physical Card for any
        User. We reserve the right to refuse Card requests or limit the number
        of physical or virtual Cards given to any User in our sole discretion.
      </p>
      <p>
        Physical or virtual cards can be issued to Users. Cards may be denied or
        revoked as a result of changes in the Issuer's policies, as required by
        law, or for any other reason we deem appropriate in our sole discretion.
      </p>
      <p>
        In the issuance of cards for Team members/Employees,
        Administrators/Controlling Officers must supply the User's full legal
        name, contact information, personal address, and date of birth in order
        to request a Card for them. Administrators/Controlling Officers can
        request that Users be given Cards with certain Evea-imposed restrictions
        features, such as certain issued cards can only be used for a specific
        category or merchant.
      </p>
      <p>Replacing Cards (Lost or Stolen)</p>
      <p>
        You are solely in charge of safeguarding your Cards, card numbers, and
        security features on Cards (such as the CVV and PIN, etc.). When a Card
        is lost, stolen, breached, or needs to be replaced, you shall quickly
        notify us and take appropriate precautions to avoid or prevent
        fraudulent transactions. Administrators can request replacement Cards
        from Issuers through your Evea Account in such instances. You may need
        to update the Card on file for any scheduled or regular payments for
        replacement Cards have new card numbers. Where card numbers have
        changed, you are solely responsible for correcting Card information
        maintained with merchants. If you do not update the Card information for
        your scheduled or recurring payments, the transactions may not be
        completed.
      </p>
      <p>1.5 Using Cards and Unauthorized Use</p>
      <p>Using Cards</p>
      <p>
        Cards are to be used solely for legitimate business transactions, not
        for personal, family, or home purposes. You agree that all transactions
        in your account will be viewed as business transactions only.
      </p>
      <p>
        As an Administrators/Controlling Officers, you are in charge of
        determining who in your organization is authorized to use Cards, as well
        as overseeing all Card transactions and activities. You are to implement
        and maintain measures to guarantee that the Cards are only used for
        legitimate Company business reasons, in accordance with the Platform
        Agreement, these Terms and Conditions, the Issuer's Terms, and
        applicable law. You are responsible for every transaction made by any
        person given access to Cards even if they are not the person associated
        with or named on the Card.
      </p>
      <p>
        Users may be allowed to withdraw cash using a Card, subject to our
        determination of eligibility, restrictions, and limitations. Any Fees,
        restrictions, or limitations on cash withdrawals will be disclosed.
      </p>
      <p>
        While using Cards, for whatever reason, transactions may be denied or
        reversed by Evea, Issuers, Card Networks, or other intermediary
        Third-Party Service Providers (including merchant acquirers). Any
        losses, damages, or harm caused by transactions that are denied or
        reversed are not Evea's responsibility
      </p>
      <p>Unauthorized Use</p>
      <p>
        If you know or suspect that your Evea Account or Cards have or may have
        been compromised, stolen, or misused, you will immediately disable User
        access to the Cards and lock the Card via your account, as well as alert
        us of any known or reasonably suspected unauthorized access or use.
        Except as allowed by Card Network standards, you are entirely
        responsible for any financial loss caused by Administrators, Users, or
        other persons granted access to the Services, your Evea Account, or
        Cards. If we suspect your Evea Account or Cards have been hacked or
        compromised, we may suspend access to your Evea Account or Cards; For if
        not doing so, would represent a danger to you, to us at Evea, Issuers,
        or any third parties.
      </p>
      <p>1.6 Disputing Charges/Transactions</p>
      <p>
        You are responsible for promptly analyzing your Transactions and Monthly
        Statements and identifying any Charges you believe are unauthorized or
        disputable.
      </p>
      <p>
        If you suspect a Charge/Transaction was made without your permission
        (i.e. unauthorized), or you were charged for a failed transaction or
        that the Monthly Statement has any inaccuracies, please notify Evea
        right away (to initiate a Chargeback) using the 'Evea Dashboard support'
        or the 'Evea transaction dispute form'. You have 30 days after the
        disputed Charge posted to your Monthly Account to report any disputed
        Charge or inaccuracy. Disputed Charge notices must include your
        information, information about the User, information about the disputed
        Charge, and an explanation of why you believe the disputed Charge was
        made in error or without authorization. Evea will conduct an
        investigation based on the information provided.
      </p>
      <p>
        You acknowledge that Evea and its Issuer are subject to Card Network
        chargeback policies, and may not be able to successfully charge back the
        Charge. The Card Networks have developed Chargeback resolution
        procedures that may require you to provide details of the disputed
        Charge or related documentation.
      </p>
      <p>
        If contested Transactions and Chargebacks are pending resolution as of
        the payment due date for the appropriate Monthly Statement, that
        Transactions/Charges will be collected on the payment date. Chargebacks
        that are resolved in your favor will be credited to your Evea Account on
        a future Monthly Statement. If you fail to pay Charges/Transactions
        connected to Chargebacks that are pending resolution on the payment
        date, we may impose Fees, lower your credit limits, or suspend access to
        your Evea Account or the Services.
      </p>
      <p>2. Payments</p>
      <p>2.1 Monthly Statements</p>
      <p>
        Statements are issued or sent out to Administrators/Controlling Officers
        monthly, after a billing cycle ends. All Charges, Fees, and Fines are
        your responsibility to pay in full. Monthly Statements outlining
        Charges, Fees, Fines, refunds, any other Card transactions, or any sums
        owing or credited to your Evea Account will be sent to you on a regular
        basis. If you believe there are any inaccuracies on your Monthly
        Statement, you must tell us immediately and file any complaints or
        Chargebacks in line with these Terms and Conditions.
      </p>
      <p>
        At the payment due date, or as otherwise specified in your Monthly
        Statement Evea will automatically debit your Primary Linked Account
        (i.e. Bank account linked to your Evea account) for all amounts owed as
        indicated on that Statement and still owed to us. Evea may debit your
        Primary Linked Account on or
      </p>
      <p>
        before 11:59pm of the payment due date. If an automatic debit fails for
        whatever reason, we will attempt to debit your Primary Linked Account
        again, or your other Linked and/or Unlinked Accounts, for the amounts
        owed.
      </p>
      <p>2.2 Failure to Pay, Offset, Collections</p>
      <p>
        We may attempt to collect amounts outstanding from any Linked and/or
        Unlinked Account, whether or not it is your Primary Linked Account or is
        currently connected to your Evea Account, if you fail to pay the full
        amount owed on time or the stated minimum amount to be paid as specified
        on your Monthly Statement. Any Linked and/or Unlinked Account may be
        used to collect partial payments for unpaid/overdue amounts, but any
        partial payment does not waive our rights and does not satisfy your
        responsibility to pay in full.
      </p>
      <p>
        Any amounts owed may be offset, debited, or collected from any of your
        bank accounts linked to the BVN or RC number/TIN you provided Evea
        through our third-party partner/assign 'AppZone.' For the benefit of
        your creditors, trustees, or receivers of Company assets, we may
        exercise this right against Company or
      </p>
      <br />
      <p>
        any of its successors or assigns, or any assignees. Even if we do not
        exercise this right prior to the preparation, filing, or issue of an
        arbitration demand, court order, or other action, it will exist.
      </p>
      <br />
      <br />
      <p>
        Failure to pay the full or minimum amount due to Evea as shown on your
        Monthly Statement when due is a violation of the Platform Agreement and
        these Terms and Conditions. You are liable for all costs and expenses
        incurred by us or Issuers in the process of collecting amounts owed that
        was not timely paid,
      </p>
      <br />
      <p>
        including legal and collection fees, as well as paying interest on such
        amounts at the maximum rate permitted by law, to the extent we decide to
        charge such interest in our sole discretion.
      </p>
      <p>2.3 Authorization to Debit Linked Accounts</p>
      <p>
        THIS SECTION PROVIDES AUTHORIZATION TO AUTOMATICALLY DEBIT YOUR LINKED
        ACCOUNTS, INCLUDING LINKED ACCOUNTS NO LONGER CONNECTED TO YOUR EVEA
        ACCOUNT, AND UNLINKED ACCOUNTS (I.E. ACCOUNTS UNLINKED TO EVEA BUT
        LINKED TO YOUR BANK VERIFICATION NUMBER [BVN]), FOR ALL AMOUNTS YOU OWE
        UNDER THESE EVEA TERMS AND CONDITIONS.
      </p>
      <p>PLEASE READ IT THOROUGHLY.</p>
      <p>
        Your Authorization to Debit Linked Accounts and any other Bank Accounts
        linked to your BVN/RC Number/TIN
      </p>
      <p>
        You authorize Evea, Issuers, or their respective successors and assigns
        to collect amounts owed under these Terms and Conditions by debiting
        funds from your Linked Accounts including Linked Accounts no longer
        connected to your Evea Account and/or other Bank Accounts unlinked to
        Evea but linked to your bank verification number (BVN) and business
        registration number (BRN or RC) or Tax identification Number (TIN). If
        Evea, Issuers, or their respective assigns use the Multi-Bank
        direct-debit repayment automation scheme, the debits will be governed by
        the rules and guidelines established by the Central Bank of Nigeria
        (CBN) for business-related direct-debit mandates, which enables Evea,
        Issuers, or their respective assigns automatically setup direct debit
        mandates on all or any accounts belonging to borrowers, and to initiate
        deductions for repayment collections, as well as recovery in the event
        of repayment defaults. You also authorize us to debit your Linked
        Accounts for verification purposes (through microdeposits or similar
        means). Debits collected under your authorization may be carried out by
        and in the name of us or our successors or assigns.
      </p>
      <p>Manner and Timing of Payment</p>
      <p>
        All amounts owed to us in connection with our provision of Services will
        be debited from Linked and/or Unlinked Accounts. You undertake to pay
        all amounts owed as directed if we are unable to collect these amounts
        through direct-debit or another way. For the payment of debts, you
        incur, we may debit Linked and/or Unlinked Accounts individually to
        satisfy those debts.
      </p>
      <p>
        You further allow Evea to debit Linked and/or Unlinked Accounts for all
        sums payable to us on any date (including the payment due date), and
        without additional notice if we find, in our sole judgment, that (a) the
        total aggregate cash balance of Linked Accounts connected to your Evea
        Account is less than the minimum cash balance required by our
        underwriting criteria, (b) Company does not fulfill one or more of our
        other underwriting or credit standards, or (c) If Evea, its successors
        or assigns Partners, Third-Party Service Providers, or Issuers is
        exposed to an unacceptable risk because of the Company itself or the
        monies owed to us.
      </p>
      <p>
        In the event there is an error in processing an electronic direct-debit,
        you authorize us to correct any mistakes that occurred during the
        processing by initiating an electronic credit or debit to the Linked
        Account in the amount of the error, on or after the day the error
        occurs.
      </p>
      <p>Withdrawing Your Authorization</p>
      <p>
        You must provide us 30 days' notice to cancel a direct-debit
        authorization from a Linked Account. You must pay all amounts owed under
        your Evea Account, including Charges, Fees, Fines, and other amounts not
        yet reflected on your Monthly Statements, before or upon providing
        notice if you withdraw the debit
      </p>
      <p>
        authorization from all Linked Accounts, and you authorize us to debit
        your Linked and/or Unlinked Account for such amounts before the
        withdrawal of authorization takes effect. Please be aware that
        Withdrawing Your Authorization for direct-debit may restrict your Card's
        credit limit and spending limit or prevents you from using your Card.
      </p>
      <p>
        Withdrawing a direct-debit authorization does not cancel the Platform
        Agreement or your commitment to pay all amounts owed under these Terms
        and Conditions and the Issuer Terms. If amounts outstanding are not paid
        in full (or minimum payment are not met as specified on the Monthly
        Statements) when due, Company is liable for all collection expenses and
        damages.
      </p>
      <p>2.4 FEES</p>
      <p>
        Fees will be disclosed to you after you have been approved for an Evea
        Account or on the pricing page of our website. Fees are subject to
        change upon 30 days' notice. Card issuance or replacement fees, card
        shipping fees, transactions fees, foreign/international transaction
        fees, usage fees, service fees, ATM
      </p>
      <p>
        withdrawal fees, late payment fees, fees for failed or returned
        payments, fees for misuse of the Services, and other fees we disclose to
        you are examples of fees we assess. Fees, along with Charges and Fines,
        are your responsibility.
      </p>
      <p>3. Defined Terms</p>
      <p>
        &#8226; Card means a physical or virtual payment card issued by an
        Issuer which is managed and controlled through your Evea Account.
      </p>
      <p>
        &#8226; Card Networks means the payment card networks such as Verve,
        Visa or Mastercard.
      </p>
      <p>
        &#8226; Charge/Transaction means a payment for goods or services made to
        a merchant that accepts payments on the applicable Card Network.
      </p>
      <p>
        &#8226; Chargeback means a transaction dispute that you initiate against
        a merchant for an unresolved dispute with the merchant or where a
        Charge/transaction is unauthorized.
      </p>
      <p>&#8226; Company means your business</p>
      <p>
        &#8226; Issuer Terms means the agreement between Company and the Issuer
        of your Card regarding your usage of the Cards.
      </p>
      <p>
        &#8226; Monthly Statement means the statements that reflect activities
        (over a 30-day period) for all Cards issued to Company identifying
        Charges/transactions, Fees, Fines, refunds, or other amounts owed or
        credited to your Evea Account during the time covered by that statement.
      </p>
      <p>
        &#8226; Primary Linked Account means the specific Linked Account you
        designate as the funding source we should use first for the repayment of
        your incurred card debt on your Evea Account. By default, the Primary
        Linked Account is the initial Account used during the underwriting
        process.
      </p>
    </div>
  );
};

export default TermsAndConditions;
