import React from 'react'
import "../scss/components/PrimaryInput.scss"

const PrimaryInput = ({type, placeholder, label}) => {
  return (
    <div className="primary-input">
      <label htmlFor="">{label}</label>
      <input type={type} placeholder={placeholder}/>
    </div>
  )
}

export default PrimaryInput