import React, { useState } from "react";
import "../scss/components/Header.scss";
import Logo from "../assets/Logo/logo1.png";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineBars, AiOutlineClose } from "react-icons/ai";

const Header = () => {
  const [overview, setOverview] = useState(false);
  const [platform, setPlatform] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="header">
      <div className="header__container">
        <div className="header__wrapper">
          <div className="header__logo">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="header__toggle" onClick={toggleNav}>
            {!isOpen ? <AiOutlineBars /> : <AiOutlineClose />}
          </div>

          <div
            className={!isOpen ? "header__left" : " header__left header__leftt"}
          >
            <div className="header__links">
              <ul>
                <li
                  onMouseEnter={() => setOverview(true)}
                  onMouseLeave={() => setOverview(false)}
                  style={{
                    color:
                      pathname === "/virtual-cards" || pathname === "/referral"
                        ? "#FFF"
                        : "#0035a3",
                  }}
                >
                  Platform
                </li>
                {overview && (
                  <>
                    <div
                      className="header__platform"
                      onMouseEnter={() => setOverview(true)}
                      onMouseLeave={() => setOverview(false)}
                      onClick={() => setOverview(false)}
                      data-aos="zoom-in"
                      data-aos-duration="500"
                      data-aos-easing="zoom-out"
                    >
                      <div className="header__platform__wrapper">
                        <div className="header__platform__left">
                          <Link
                            to="/business-card"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <div>
                              <p>Business Credit Card</p>
                              <p>Access a credit line, issue & manage cards.</p>
                            </div>
                          </Link>

                          <Link
                            to="/spend-management"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <div>
                              <p>Spend Management</p>
                              <p>
                                Budgeting software that helps in financial
                                control
                              </p>
                            </div>
                          </Link>

                          <Link
                            to="/expense-management"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <div>
                              <p>Expense Management</p>
                              <p>
                                Manage receipts memos, categorisation & report.
                              </p>
                            </div>
                          </Link>
                        </div>

                        <div className="header__platform__middle">
                          <div>
                            <div>
                              <p>Accounting Integrations</p>
                              <p className="header__coming">Coming soon</p>
                            </div>

                            <p>Sync and close the books faster.</p>
                          </div>
                          <div>
                            <div>
                              <p>Expense Policies</p>
                              <p className="header__coming">Coming soon</p>
                            </div>

                            <p>
                              Set up spend policies for teams, with approvals
                              and alerts
                            </p>
                          </div>
                          <div>
                            <div>
                              <p>Business Account </p>
                              <p className="header__coming">Coming soon</p>
                            </div>

                            <p>
                              IBAN Account no., currency exchanges,
                              International money transfers.
                            </p>
                          </div>
                        </div>

                        <div className="header__platform__right">
                          <div>
                            <p>Mobile App</p>
                            <p className="header__coming">Coming soon</p>
                          </div>
                          <Link
                            to="/virtual-cards"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <p>Virtual Cards</p>
                          </Link>
                          <div>
                            <p>Rewards/Perks</p>
                            <p className="header__coming">Coming soon</p>
                          </div>

                          <a
                            href="https://www.usealx.evea.com.ng/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div>
                              <p>Alx</p>
                              <p className="header__coming">Coming soon</p>
                            </div>
                          </a>

                          <div>
                            <p>iExchange</p>
                            <p className="header__coming">Coming soon</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <li
                  onMouseEnter={() => setPlatform(true)}
                  onMouseLeave={() => setPlatform(false)}
                  style={{
                    color:
                      pathname === "/virtual-cards" || pathname === "/referral"
                        ? "#FFF"
                        : "#0035a3",
                  }}
                >
                  Company
                </li>

                {platform && (
                  <>
                    <div
                      className="header__overview"
                      onMouseEnter={() => setPlatform(true)}
                      onMouseLeave={() => setPlatform(false)}
                      onClick={() => setPlatform(false)}
                      data-aos="zoom-in"
                      data-aos-duration="500"
                      data-aos-easing="zoom-out"
                    >
                      <div className="header__overview__wrapper">
                        <div className="header__overview__left">
                          <Link
                            to="/about-us"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <div>
                              <p>About Evea</p>

                              <p>Our mission, as well as our narrative</p>
                            </div>
                          </Link>

                          <Link
                            to="/work-with-us"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <div>
                              <p>Work with Us</p>

                              <p>We’re looking for exceptional people!</p>
                            </div>
                          </Link>

                          <Link to="/">
                            <div>
                              <p>Help Center</p>
                              <p>Find the answers you’re looking for</p>
                            </div>
                          </Link>
                        </div>

                        <div className="header__overview__middle">
                          <Link to="/">
                            <div>
                              <div>
                                <p>Partners</p>
                              </div>

                              <p>Sign in | Sign up to become a partner</p>
                            </div>
                          </Link>

                          <Link
                            to="/referral"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <div>
                              <div>
                                <p>Referral</p>
                              </div>
                              <p>Refer a founder | business owner</p>
                            </div>
                          </Link>
                        </div>

                        <div className="header__overview__right">
                          <Link
                            to="/contact-us"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <p>Contact</p>
                          </Link>

                          <Link to="/">
                            <p>Resources Library</p>
                          </Link>
                          <Link to="/">
                            <p>Your Stories</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <li>
                  <Link
                    to="/pricing"
                    style={{
                      color:
                        pathname === "/virtual-cards" ||
                        pathname === "/referral"
                          ? "#FFF"
                          : "#0035a3",
                    }}
                  >
                    Pricing
                  </Link>
                </li>
              </ul>
            </div>

            <div className="header__action">
              <Link
                to="https://app.evea.africa/"
                target="_blank"
                onClick={() => setIsOpen(!isOpen)}
                style={{
                  color:
                    pathname === "/virtual-cards" || pathname === "/referral"
                      ? "#FFF"
                      : "#0035a3",
                }}
              >
                Sign In
              </Link>
              <Link
                to="https://app.evea.africa/signup"
                style={{
                  color:
                    pathname === "/virtual-cards" || pathname === "/referral"
                      ? "#FFF"
                      : "#0035a3",
                }}
                target="_blank"
              >
                <button
                  style={{
                    border:
                      pathname === "/virtual-cards" || pathname === "/referral"
                        ? "1px solid #FFF"
                        : "1px solid #0035a3",
                    color:
                      pathname === "/virtual-cards" || pathname === "/referral"
                        ? "#FFF"
                        : "#0035a3",
                  }}
                >
                  Sign Up
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
