import React from "react";
import "../../scss/components/Privacy/General.scss";

const Platform = () => {
  return (
    <div className="privacyDashboard">
      <p>Evea’s Platform Agreement</p>
      <br />
      <p>Updated April, 12 2022</p>
      <br />
      <b>Introduction</b>
      <br />
      <p>
        This Platform Agreement governs your use of the Services and is a legal
        agreement between Company and Evea. Unless otherwise specified,
        "Company" or "you" refers to the company that is applying for or has
        already opened an Evea Account in order to utilize the Services, and
        "Evea" or "we" refers to Evea Inc. and its past, present, or future
        affiliates, successors, and assigns.
      </p>
      <br />
      <p>
        You agree to be bound by this Platform Agreement, including the
        applicable Card/Product Terms and Conditions, and to receive all Notices
        and communications from Evea electronically by submitting your
        application to create an Evea Account.
      </p>
      <br />
      <p>
        You can only open and maintain an Evea Account and use the Services if
        you agree to the terms of this Platform Agreement, so please read it
        carefully.
      </p>
      <br />
      <p>
        Any disputes originating under this Platform Agreement must be resolved
        in an individual arbitration, and not a class arbitration, as required
        by this Platform Agreement. You recognize and understand that by
        accepting this Platform Agreement, you are waiving your right to a jury
        trial, court trial or to file any claims
      </p>
      <br />
      <p>
        in court arising out of this Platform Agreement, as well as any right to
        join in a class action or class proceeding pertaining to this Platform
        Agreement; Please refer to Section 4.7 for further information.
      </p>
      <br />
      <b>1. Evea Platform</b>
      <br />
      <b>1.1 Services</b>
      <p>
        The Services allow you to have access to credit, issue and manage Cards
        for your business and Users; manage and control spending, expense
        management and reporting; and access other optional services through
        your Evea Account, subject to the terms and conditions of the Evea Terms
        and any related addenda
      </p>
      <br />
      <p>
        hereto. Evea has the right to alter, modify or provide new Services at
        any time without prior notice; however, Evea will use reasonable efforts
        to notify you of any major changes to Services on which you rely.
      </p>
      <br />
      <b>2. Opening an Evea Account</b>
      <br />
      <b>2.1 Eligibility</b>
      <br />
      <p>
        Companies incorporated and registered in the Nigeria (such as LTD/LLCs,
        Business Names) are eligible to apply for an Evea Account and utilize
        the Services. Individual consumers, unregistered business,
        unincorporated partnerships, and companies established and/or registered
        outside of Nigeria are not authorized to open or use an Evea Account or
        the Services for now, nor are they permitted to attempt to do so.
      </p>
      <br />
      <p>
        You acknowledge and understand that transactions on your Evea Account
        and your use of the Services are commercial in nature, and that certain
        consumer protection laws and consumer-specific rules do not apply
      </p>
      <b>2.2 Requirements and Prohibited Activities</b>
      <br />
      <b>Company’s Natural Person Applicant Representation</b>
      <br />
      <p>
        To submit an application to open an Evea Account, the Natural Person
        Applicant representing and guarantying for the company must be an
        individual that warrants authority within the company and an also
        authorized representative of the Company.
      </p>
      <br />
      <p>
        The Natural Person Applicant has the authority to provide Company
        information, submit the application on Company's behalf, engage into
        binding agreements on Company's behalf, authorize debits from Linked
        Accounts, and manage Company's Evea Account.
      </p>
      <br />
      <p>
        The Natural Person Applicant must acknowledge, with current and accurate
        information, that:
      </p>
      <br />
      <p>
        • Company is a business entity that is duly registered and operational
        in Nigeria
      </p>
      <br />
      <p>• Company has been operational for over 6 months.</p>
      <br />
      <p>• Company is not engaged in any Prohibited Activities</p>
      <br />
      <p>
        • All Linked Accounts specified by Company are business accounts, not
        personal/individual
      </p>
      <br />
      <p>
        accounts and are not maintained or utilized for personal, family, or
        household purposes.
      </p>
      <br />
      <p>
        • Company has reviewed this Platform Agreement and the Terms and
        Conditions, or policies.
      </p>
   
      <b>Required Information</b>

      <p>
        To apply and maintain an Evea Account and its Services, certain
        information must be provided:
      </p>
   
      <p>i. Company Data:</p>
      <br />
      <p>
        • Business Info, such as Registered Business Name, Industry, Nature of
        the business, etc.
      </p>
      <br />
      <p>• Business address, such as proof of address</p>
      <br />
      <p>
        • Business Details, such as RC Number, Tax identification number (TIN),
        Incorporation year.
      </p>
      <br />
      <p>• Incorporation papers</p>
      <br />
      <p>• Business Ownership</p>
      <br />
      <p>
        • Financial data/information, such as Company’s monthly expense
        information, etc.
      </p>
      <br />
      <p>• Details for your Linked Accounts (i.e. Bank Account(s))</p>
      <br />
      <p>Other information may be required or requested from time to time.</p>
      <br />
      <p>ii. Personal Data (of the Natural Person Applicant):</p>
      <br />
      <p>• Full legal names</p>
      <br />
      <p>• Email address and Contact information</p>
      <br />
      <p>• Title or Role in the Company’s team</p>
      <br />
      <p>
        • Valid National Identification (either Int. passport, voter’s card,
        NIN, driver’s license or other
      </p>
      <br />
      <p>national ID)</p>
      <br />
      <p>• Bank verification number (BVN)</p>
      <br />
      <p>• Date of Birth of Administrator (i.e. the Administrator)</p>
      <br />
      <b>iii. Bank Connection:</b>
      <br />
      <p>
        At least one Bank Account must be linked to your Evea Account. Every
        linked account must be a business account, not a personal account. You
        authorize us to verify that the account information you submit for your
        Linked Account is valid and that the Linked Account is yours i.e. the
        business.
      </p>
      <br />
      <p>
        As instructed by the Nigerian Government, Financial institutions must
        gather, verify, and record Company Data and Personal Data identifying
        firms and their Beneficial Owners and Control Persons in order to assist
        the government in combating the funding of terrorism, money laundering
        operations, and other financial crime. You agree to supply all necessary
        information to create and maintain your Evea Account, as well as to keep
        such information up to date. As indicated in our Terms and Conditions,
        we may share this information with our Card Partners and Third-Party
        Service Providers for these reasons.
      </p>
      <br />
      <p>Verification and validation of information provided</p>
      <br />
      <p>
        When creating and managing your Evea Account, Evea and its Partners rely
        on the veracity of the information you give. During the course of
        applying or obtaining certain Services, you may be needed to verify
        previously supplied information or supply new information.
      </p>
      <br />
      <p>
        Before submitting Personal Data to Evea, you agree that you have gotten
        proper consent and authorization from any person whose Personal Data you
        providing.
      </p>
      <br />
      <p>
        You understand and agree that, as specified in the Privacy Policy and
        Terms and Conditions, we may use and share Company Data and Personal
        Data to Partners and Third-Party Service Providers to verify and
        authenticate the information you have supplied and establish your
        eligibility for the Services.
      </p>
      <br />
      <p>
        While your application is being reviewed, we may reject or approve it,
        or we may provide you temporary, restricted access to the Services or
        your Evea Account. If the information you supplied is incomplete,
        incorrect, or out of date, we will decline your application, halt the
        supply of the Services to you, or suspend or cancel your Evea Account.
      </p>
      <br />
      <b>Consent to Electronic Signature and Communications</b>
      <p>
        You agree that submitting your application to create an Evea Account and
        electronically signalling your acceptance to the provisions of this
        Platform Agreement during the application process is equivalent to
        signing this Platform Agreement. You also accept that your electronic
        consent has the same legal impact as a physical signature and will
        continue to have the same legal effect in the future. You consent to us
        sending you Notices and account statements electronically by consenting
        to the provisions of the Platform Agreement, and you understand that
        your consent has the same legal binding validity as a physical
        signature.
      </p>
      <br />
      <b>2.3 Credit Report Authorization</b>
      <br />
      <p>
        You authorize us to obtain consumer credit records, which we will use
        while evaluating your Company’s Evea application. Once your Evea Account
        gets approved and become an Evea credit card cardholder, you also
        authorize us to obtain consumer credit reports and other information
        about you (the Natural Person Applicant/Administrator) from time to time
        for any legal or commercial purpose, including:
      </p>
      <br />
      <b>2.4 Prohibited and Restricted Activities</b>
      <br />
      <p>
        Your Evea Account and the Services may only be used for legitimate
        business expenses for the Company, and not for the following:
      </p>
      <br />
      <p>
        (a). used for any unlawful or prohibited purpose under this Platform
        Agreement or the Terms and Conditions.
      </p>
      <br />
      <p>
        (b). used for any personal, family, or home use, or any other activities
        not for the benefit of the Company.
      </p>
      <br />
      <p>(c). used for transactions that involves any Prohibited Activities.</p>
      <br />
      <p>(d). used by third parties who are unaffiliated to the Company</p>
      <br />
      <p>
        Any Evea Accounts that we suspect or know are involved in any of the
        prohibited activities or otherwise do not comply with the restrictions
        in this platform agreement, as determined in Evea’s sole discretion or
        any other restricted category as determined by Evea or any Issuer in
        their sole discretion will be terminated/closed.
      </p>
      <br />
      <p>
        Company that engages in certain restricted activities as determined in
        Evea’s sole discretion will be required to provide additional
        information in order to create or maintain an Evea Account. At any
        moment and from time to time, we may update the list of Prohibited
        and/or Restricted activities. You agree to check
      </p>
      <br />
      <p>
        these lists on a frequent basis and to contact us if you have any
        queries about how this list may relate to the Company's operations.
      </p>
      <br />
      <b>2.4 Data and Privacy</b>
      <br />
      <p>
        Through your use of the Cards, the Services (including those received
        through Linked Accounts), and Third-Party Services; The Card Networks,
        Evea, and Issuers acquire and process these Company Data and Personal
        Data. The Card Networks, Evea, and Issuers may use and disclose Company
        Data and Personal Data in the following ways:
      </p>
      <br />
      <p>(a). to provide Services to Company and Users;</p>
      <br />
      <p>(b). as required by law or Card Network rules;</p>
      <br />
      <p>(c). for underwriting, identity verification, and fraud prevention;</p>
      <br />
      <p>
        (d). to verify account balances and account information, identify
        spending patterns, and determine credit limits;
      </p>
      <br />
      <p>(e). to analyze and report transactions;</p>
      <br />
      <p>(f). to report Company performance to credit bureaus.</p>
      <br />
      <p>(g). for internal analytics and reporting;</p>
      <br />
      <p>(h). for dispute resolution; and as authorized by law.</p>

      <p>
        Without your approval, we will not share any Company Data or Personal
        Data with third parties for the purpose of marketing their unrelated
        products. However, we may use Company Data and Personal Data to identify
        Services, Third-Party Services, and programs that we believe may be of
        interest to you for the purpose of promoting them to you, such as
        rewards, discount or benefits programs.
      </p>
   
      <p>
        Evea adheres to the Privacy Policy for processing Personal Data. You
        recognize, understand, and agree that in order to provide the Services,
        we will collect, handle, and disclose Company Data and Personal Data in
        accordance with this Platform Agreement and the Privacy Policy. Evea
        shall establish safeguards to prevent the risk of loss or unintentional
        disclosure of Company Data or Personal Data when we share it with our
        subcontractors or partners.
      </p>
     
      <b>3. Managing and Using an Evea Account</b>
     
      <b>3.1 Authorized Users</b>
    
      <p>Administrators</p>
    
      <p>
        When submitting your application, you must choose at least one
        Administrator to handle your Evea Account. You agree that the Natural
        Person Applicant will be the Administrator for your Evea Account.
        Administrators must have the necessary capacity and ability to conduct
        businesses and manage Company's Evea Account, including connecting and
        authorizing debits from Linked Accounts, consenting to any new or
        updated terms and conditions contained in this Platform Agreement or
        other agreements or policies incorporated in this Platform Agreement,
        monitoring Account activities and statements; and Company certifies that
        any individual named as an Administrator does have the authority. If an
        Administrator no longer has the necessary power and authority, Company
        must notify us quickly and appoint a new Administrator for the Evea
        Account.
      </p>
      <br />
      <strong>
        <br />
        3.2 Responsibility for Use Access Changes to the Services
      </strong>
    
      <p>
        The Company is accountable and liable for the activities and inactions
        of its Administrators, Users, and anyone else who uses the Company's
        Evea Account using given Credentials.
      </p>
     
      <p>
        The Company is responsible for any breach or violation of this Platform
        Agreement by its Administrators and Users.
      </p>
    
      <b>3.3 Access to Account</b>
   
      <p>
        You shall keep your Evea Account safe and secure, and only give access
        to those you've given permission to use the Services on your behalf; and
        you will not allow anybody else to use the Services without your
        permission.
      </p>
   
      <p>
        You will protect the privacy, confidentiality, and security of User
        Credentials by taking all precautions. You shall rigorously and
        routinely monitor the behaviour/activities of Users who access the
        Services, and you will take all precautions to protect Cards and its
        details, mobile devices, web browsers, and other items
      </p>
      <br />
      <p>used to access or use the Evea Services.</p>
    
      <p>
        If you believe or suspect that your Evea Account has been hacked,
        compromised or may be misused, please contact us immediately.
      </p>
  
      <b>3.4 Fees and Disclosures</b>
   
      <p>
        Certain transactions fees, misuse of Account or Services fees, late or
        failed repayment fees, interest charged on late or failed or partial
        payment (which is not the full or minimum payment made), and other fees
        linked with some Services, which Evea will disclose prior to charging.
        Fees will be disclosed to you when you create an Evea Account, begin
        using a new Service, or visit our website.
      </p>
      <br />
 
      <b>3.5 Rewards </b>
     
      <p>
        Evea has the right to choose when, how, and under what conditions
        Company or its Users are eligible to earn rewards. The Rewards Terms and
        this Platform Agreement apply to all rewards. Some or all benefits,
        including cashback reward credits, may be unavailable for certain Card
        types or transactions. As stated in the Rewards Terms, rewards may be
        subject to restrictions, cancellation, or forfeiture. Any entitlement to
        incentives or benefits will expire if this Agreement is terminated, your
        Evea Account is closed, or there is a breach of this Agreement.
      </p>
      <br />

      <b>3.6 Changes to the Services</b>
      <br />

      <p>
        At any moment, we may introduce new Services or modify current ones.
        Additional Terms will apply to some of these services. You recognize and
        accept that, in order to use some Services, you must first agree to the
        Additional Terms that we will provide separately, and such additional
        terms will also be incorporated by reference and become part of this
        Platform Agreement. Services may vary over time, with some being
        discontinued and others being introduced.
      </p>
      <br />
 
      <p>
        We cannot promise that all of the Services will be available to you at
        all times.
      </p>
      <br />
   
      <b>3.7 Updates to Company Information</b>
      <br />

      <p>Providing Data</p>
      <br />
    
      <p>
        You shall keep your Evea Account updated, and correct at all times with
        accurate Company Data and each User's Personal Data
      </p>
      <br />

      <p>
        We may request additional information from you at any time, including
        Company Data (copies of government-issued documents, business licenses,
        or other information related to your business) and Personal Data (copies
        of government-issued personal identification and proof of address) to
        verify Beneficial Owners or Control Persons, validate information you
        provided, verify the identity of Administrators or Users, or assess the
        financial condition of the Company.
      </p>
     
      <p>Notification of Corporate and Business Changes</p>
    
      <p>
        At all times, the company must be lawfully constituted and in good
        standing under the laws of its jurisdiction of formation.
      </p>
     
      <p>
        If any of the following events occur, you shall quickly notify us in
        writing via mail:
      </p>
     
      <p>• Nature of business has changed.</p>
      <br />
      <p>
        • Business has started participating in any Prohibited or Restricted
        Activities.
      </p>
      <br />
      <p>• Beneficial Owners or Control Persons have changed.</p>
      <br />
      <p>
        • There is a major change in control or ownership of your company
        (direct or indirect).
      </p>
      <br />
      <p>
        • A liquidation, voluntary bankruptcy, or insolvency procedure is
        planned or anticipated.
      </p>
      <br />
      <p>
        • You are a party to a dispute or a regulatory process in which
        allegations are made that, if upheld in a legal or regulatory procedure
        or an alternative dispute resolution venue, would have a substantial
        impact on the Company's financial status.
      </p>
    
      <b>3.8 Identification as Customer</b>
   
      <p>
        During the period of this Platform Agreement, we may refer to you as an
        Evea customer on our website or/and in other communications. We shall
        not make any deceptive statements about partnerships or alliances. Evea
        has a limited permission to use Company trademarks or service marks for
        this purpose, by granting the permission to Evea. If you do not want to
        be identified as an Evea customer, please let us know, and we will
        remove any references to you from our website or/and other
        communications.
      </p>
    
      <b>3.9 Property | Ownership and Licenses</b>
   
      <p>
        Evea is the only owner of all Evea property. Evea Property may be used
        only for the purpose specified in this Platform Agreement and the Terms
        and Conditions by Company, Administrators, and other Users. Evea
        Property or sections of Evea Property may not be modified, reverse
        engineered, created derivative works from, or disassembled, nor may you
        register, seek to register, or claim ownership in Evea Property or
        portions of Evea Property.
      </p>

      <p>
        Evea offers you a nonexclusive, non-transferable license to use Evea
        Property in accordance with this Platform Agreement and as delivered
        through the Services. Unless we cancel it sooner, this license expires
        when this Platform Agreement is terminated.
      </p>
 
      <b>3.10 Beta Services and Feedback</b>
 
      <p>
        We may make Beta Services accessible to you from time to time in order
        to test new features or products and obtain feedback. We have the right
        to modify or terminate Beta Services at any time. If you use Beta
        Services, you agree to keep information about the Beta Services
        confidential until we publicly announce
      </p>
      <br />
      <p>
        the features, products, or programs. You agree that Beta Services may be
        unstable and prone to frequent, significant interruptions as well as
        incorrect output and functionality. You agree not to use any Beta
        Services for any mission-critical or other vital functions unless you
        take adequate safeguards to avoid loss or damage as a result of such
        usage. We are allowed to utilize any Feedback we receive concerning the
        Services or Beta Services in any way we see fit; and Evea is under no
        duty to reward or credit you for any Feedback you offer/submit,
        regardless of whether or not we utilize, monetize, or exploit it in any
        way
      </p>
      <br />
      <br />
      <strong>3.11 Assignment</strong>
      <br />
      <br />
      <p>
        Without Evea's express written approval, you may not assign or transfer
        this Platform Agreement or any of your rights under it, or delegate any
        of your performance requirements or duties. You must provide us prior
        writing notice if you desire to make such a transfer or assignment, or
        if the ownership of Company changes. If we agree to such a transfer or
        assignment, the assignee or successor must acquire all of Company's
        rights, responsibilities, and liabilities under this Platform Agreement
        and your relationship with Evea, and must abide by all of the Platform
        Agreement's conditions.
      </p>
      <br />
      <br />
      <p>
        Without restriction, Evea has the right to assign, pledge, delegate, or
        otherwise transfer this Platform Agreement, as well as its rights,
        powers, remedies, responsibilities, and duties, at any time, without
        providing you any notice. Any such assignee will have the same rights as
        if it had been originally mentioned first in this Platform Agreement.
      </p>
      <br />
      <br />
      <p>
        3.12 Account Holds, Suspensions, Terminations, and Other Actions We May
        Take
      </p>
      <br />
      <br />
      <p>
        We may take a number of actions with or without Notice to protect Evea,
        its partners, and others if we believe, in our sole discretion, that you
        have violated the terms of this Platform Agreement, by engaging in any
        Prohibited Activities or Restricted Activities, or violated our or
        others’ rules and rights, engaged in fraudulent activities, or created
        an undue risk of harm for us, our partners or others.
      </p>
      <br />
      <br />
      <p>Among the actions we could take are:</p>
      <br />
      <br />
      <p>
        • Immediately and without penalty, terminate this Platform Agreement
        with you, and restricts your company's (Administrators or Users) access
        to its Evea Account, and/or close or suspend your company’s Evea
        Account.
      </p>
      <br />
      <p>• Refuse to provide Evea’s Services to the company again.</p>
      <br />
      <p>
        • Debit all amounts owed from your Linked/Unlinked Account, even if
        payment is not yet due.
      </p>
      <br />
      <p>
        • Inform other financial institutions, other impacted third-party
        partners, law enforcement, and/or other regulators about your fraudulent
        conduct/activities.
      </p>
      <br />
      <p>
        • Correct any false information submitted to us by the Natural Person
        Applicant, any Administrator or User, or any other employee or associate
        working under or affiliated with the company’s Evea Account.
      </p>
      <br />
      <p>
        • Take legal action against the company, its Natural Person Applicant,
        any Administrator or User or any other person operating on the company’s
        Evea Account
      </p>
   
      <p>
        If for any reason you breached this Platform Agreement or engaged in any
        Prohibited or Restricted Activities, you're liable for any damages
        caused to Evea as a result of your violation and/or such activities.
      </p>

      <p>
        We will notify you if your Evea Account is closed or your usage of the
        Services is terminated for any reason. To the extent permitted by law,
        any reasonable cost we incur while attempting to reach you or any costs
        we incur in the process of terminating your Evea Account will be
        deducted from any of your Linked or
      </p>
      <br />
      <p>
        Unlinked Bank Account. You will remain accountable to us for any
        transactions or liabilities related with your Evea Account, as well as
        any losses or costs we incur, whether they occur before, during, or
        after the account closing process.
      </p>
 
      <p>
        All reversals, chargebacks, claims, fees, fines, penalties, negative
        balances, and any liabilities incurred by Evea, its partners, or a third
        party as a result of your breach of this Platform Agreement and/or your
        use of the Services are your responsibility.
      </p>
 
      <p>
        The measures we may take in regard to your Evea Account may be based on
        confidential criteria that are necessary for our risk management and the
        protection of Evea, our partners, Third-party service providers, and
        others. When analysing the risk connected with your Evea Account, we may
        apply proprietary fraud
      </p>

      <p>
        and risk modelling. Furthermore, we may be prohibited from sharing some
        information to you concerning such decisions or acts by law, regulation,
        or a governmental body. You acknowledge that we are under no duty to
        provide you with information about our risk management or security
        practices.
      </p>
      <br />
      <br />
      <p>
        Nothing in this section restricts our rights to decline to offer
        Services to anybody, at any time and for any reason consistent with
        applicable law, at our sole discretion.
      </p>
      <br />
      <br />
      <strong>4. Additional Terms</strong>
      <br />
      <br />
      <strong>4.1 Term and Termination</strong>
      <br />
      <br />
      <p>
        This Platform Agreement becomes effective immediately you accept its
        terms when creating an Evea Account and remains in effect until either
        you or Evea terminates it in accordance with the Terms and Conditions or
        as specified in this Platform Agreement.
      </p>
      <br />
      <br />
      <p>
        Evea has the right, with or without notice, to cancel this Platform
        Agreement, or suspend your Evea Account or Cards, at any time and for
        any reason. Any Cards associated with the Company's Evea Account will
        instantly expire if the Ramp Account is terminated.
      </p>
      <br />
      <br />
      <p>
        By stopping to use the Services, paying all amounts owed, and providing
        notice to us, you may ask us to terminate this Platform Agreement. We
        may decline to terminate this Platform Agreement or close your Evea
        Account if we suspect that the Evea Account is being closed/terminated
        to circumvent any legal or
      </p>
      <br />
      <p>
        regulatory inquiry or investigation. You are liable for any Charges,
        Fees, Fines, damages, and other sums payable to us as a result of your
        action or inaction, including those assessed or that may arise after the
        termination of your Evea Account, as well as any costs we may spend in
        closing your Evea Account.
      </p>
      <br />
      <br />
      <p>
        Certain provisions of this Platform Agreement shall survive termination
        if the Platform Agreement is terminated, including:
      </p>
      <br />
      <br />
      <strong>• Section 2.5: Data and Privacy </strong>
      <br />
      <strong>• Section 3.2: Responsibility for Use</strong>
      <br />
      <strong>• Section 3.3: Access to Account</strong>
      <br />
      <strong>• Section 3.10: Beta Services and Feedback</strong>
      <br />
      <strong>• Section 3.11: Assignment</strong>
      <br />
      <strong>
        • Section 3.12: Account Holds, Suspensions, Terminations, and Other
        Actions We May Take
      </strong>
      <br />
      <strong>• Section 4.1: Term and Termination</strong>
      <br />
      <strong>
        • Section 4.2: Notices, Communication/User Notifications, and Monitoring
      </strong>
      <br />
      <strong>• Section 4.3: Limitation of Liability</strong>
      <br />
      <strong>• Section 4.4: Evea’s Disclaimer of Warranties</strong>
      <br />
      <strong>• Section 4.5 Indemnification</strong>
      <br />
      <strong>• Section 4.6: Governing Law </strong>
      <br />
      <strong>• Section 4.7: Binding Arbitration</strong>
      <br />
      <strong>• Section 4.8: Legal Process</strong>
      <br />
      <br />
      <p>
        If you reapply or reopen your Evea Account or use or attempt to use the
        Services or Cards you are consenting to the Platform Agreement in effect
        at that time.
      </p>
      <br />
      <br />
      <strong>
        4.2 Notices, Communication/User Notifications, and Monitoring
      </strong>
      <br />
      <br />
      <p>
        You accept that by creating an Evea Account you assent your electronic
        signature, thereby giving Evea the consent to send you the Natural
        Person Applicant, Administrators, or Users Notices, Messages, and User
        Notifications electronically. You acknowledge that your consent has the
        same legal validity as a physical
      </p>
      <br />
      <p>
        signature. You realize that unless you agree to receive Notices
        electronically, you will not be able to use the Services or Cards. Only
        by cancelling your Evea Account will you be able to revoke your
        agreement to receive Notices electronically.
      </p>
      <br />
      <br />
      <p>
        In accordance with the terms outlined in this section, you agree that
        Evea and any agencies operating on our behalf may send Notices,
        Messages, including through text or SMS, to the Natural Person
        Applicant, Administrators, or Users in connection with an application
        made on behalf of Company, your Evea Account or any activity associated
        with your Evea Account.
      </p>
      <br />
      <br />
      <p>
        Using the contact data (such as emails, phone numbers) submitted to us
        or our agents by the Natural Person Applicant, Administrators, or Users,
        we and our agents may issue Notices or interact with you via mail,
        messages or phone calls.
      </p>
      <br />
      <br />
      <p>
        Payment notices, legal notices, and any other necessary notices
        pertaining to your Company's Evea Account will be delivered to the
        Natural Person Applicant and/or Administrators via email or your Evea
        Account, and will be deemed received, 24 hours after they are sent, with
        the exception of Notices, Messages,
      </p>
      <br />
      <p>
        or User Notifications captioned or otherwise designated as "URGENT" or
        “PRIORITY” or “EMERGENCY” or "READ IMMEDIATELY" which will be deemed
        received when sent.
      </p>
      <br />
      <br />
      <p>
        Notices, Messages and User Notifications may involve the use of
        Credentials, notifications regarding Services, Cards, or Charges (i.e.
        transactions), and giving the option to react with information about the
        Charges on Cards or the Evea Account to the Natural Person Applicant,
        Administrators, and Users. The Natural Person Applicant, Administrators,
        or Users may opt out of receiving certain Notices or Notifications (such
        as mail or messages) through the Evea Account, but this may limit the
        usage of some Services and may expose the Company to financial risks.
      </p>
      <br />
      <br />
      <p>
        To receive Notices accurately, Company’s Natural Person Applicant,
        Administrators, and Users must use updated web browsers, updated
        computer and mobile device operating systems. You accept that it is
        exclusively your responsibility to ensure that no Notices are blocked or
        delayed by any spam filter or other means. If you are having or suspect
        you are having difficulty getting Notices, simply notify us right away.
      </p>
      <br />
      <br />
      <p>
        All charges imposed by Internet or mobile service providers for sending
        or receiving Notices electronically are the responsibility of the
        Company’s Natural Person Applicant, Administrators, or Users.
      </p>
      <br />
      <br />
      <p>
        When using any of Evea's product (websites, web applications or mobile
        applications) or using the Services, you accept/agree that Evea may
        monitor or record the interactions or activities of Administrators,
        Users, or persons granted access to the Services or your Evea Account.
        Any communications may be monitored
      </p>
      <br />
      <p>
        or recorded by us or our agents for quality assurance or other
        legitimate business objectives.
      </p>
      <br />
      <br />
      <strong>4.3 Limitations of Liability</strong>
      <br />
      <br />
      <p>
        In relation to your Evea Account and your use of the Services, Evea's
        liability is restricted. Unless prohibited by applicable law or rules,
        Evea will not be liable to you for any consequential, indirect, special,
        exemplary, or punitive damages, lost earnings or revenue, reputational
        harm, physical injury, or property damage
      </p>
      <br />
      <p>
        arising from or related to your Evea Account, Evea's systems and
        Services, your use of or inability to use the Services or Cards, or this
        Platform Agreement, whether or not we were advised of their possibility
        by you or third parties.
      </p>
      <br />
      <br />
      <strong>4.4 Evea’s Disclaimer of Warranties</strong>
      <br />
      <br />
      <p>
        THE SERVICES, BETA SERVICES, AND EVEA PROPERTY, ARE PROVIDED AS IS AND
        AS AVAILABLE TO YOU. EVEA EXPRESSLY DISCLAIMS ALL EXPRESS, IMPLIED, OR
        STATUTORY WARRANTIES, AND YOU ACKNOWLEDGE THAT NO EVEA PERSONNEL IS
        AUTHORIZED TO MAKE ANY SUCH WARRANTY, AND NOTHING AT ALL IN THIS
        PLATFORM AGREEMENT IS TO BE INTERPRETED AS PROVIDING OR APPEAR TO
        SUGGEST ANY SUCH WARRANTY TO YOU.
      </p>
      <br />
      <br />
      <p>
        EVEA DOES NOT PROVIDE OR CONTROL THIRD-PARTY SERVICES. EVEA DOES NOT
        PROVIDE SUPPORT FOR THIRD-PARTY SERVICES AND DISCLAIMS ALL LIABILITY FOR
        FAILURES OR LOSSES CAUSED BY THIRD-PARTY SERVICES.
      </p>
      <br />
      <br />
      <p>
        EVEA IS NOT AND WILL NOT BE RESPONSIBLE FOR ANY DAMAGES, HARM, OR LOSSES
        TO YOU RESULTING FROM UNAUTHORIZED ACCESS OR USE OF YOUR EVEA ACCOUNT OR
        THE SERVICES. EVEA EXPRESSLY DISCLAIMS SUCH LIABILITY.
      </p>
      <br />
      <br />
      <p>EVEA EXPRESSLY MAKES NO WARRANTIES AND DOES NOT GUARANTEE THAT</p>
      <br />
      <br />
      <p>
        (A) THE SERVICES AS WELL AS INFORMATION PROVIDED UNDER THIS PLATFORM
        AGREEMENT ARE ERROR-FREE;
      </p>
      <br />
      <p>(B) THE SERVICES WILL MATCH YOUR EXACT REQUIREMENTS OR NEEDS;</p>
      <br />
      <p>
        (C) AT ANY TIME OR LOCATION, COMPANY, ADMINISTRATORS, OR USERS WILL BE
        ABLE TO USE THE SERVICES;
      </p>
      <br />
      <p>
        (D) CERTAIN MERCHANTS WILL ALLOW PURCHASES TO BE MADE WITH CARDS ISSUED
        BY AN ISSUER;
      </p>
      <br />
      <p>
        (E) THE SERVICES WILL BE UNINTERRUPTED, SAFE, AND FREE From HACKING,
        VIRUSES, OR MALICIOUS CODE; AND
      </p>
      <br />
      <p>
        (F) WE WILL CORRECT ANY Imperfections IN THE SERVICES, EVEN IF WE HAVE
        BEEN INFORMED OF THEM.
      </p>
      <br />
      <br />
      <strong>4.5 Indemnification</strong>
      <br />
      <br />
      <p>
        You agree to indemnify Evea, our partners, affiliates, and Third-Party
        Service Providers (each an "Indemnitee"), and hold harmless from and
        against any losses, liabilities, damages, claims, costs or expenses
        (including reasonable attorneys' fees) arising out of or relating to
        claims, proceedings, suits, or actions brought by or initiated against
        Evea or any of the other Indemnitees specified above by any third party
        due to the breach by Company or by any Administrator or User of this
        Platform Agreement, the Terms and Conditions, any terms applicable to
        any Third-Party Services and/or any other agreements with Evea or any
        Financial Institution Partner or that otherwise relate to the Services,
        Cards or Third Party Services; Company's use of any Beta Services or
        Third-Party Services.
      </p>
      <br />
      <br />
      <strong>4.6 Governing Law</strong>
      <br />
      <br />
      <p>
        This Platform Agreement shall be interpreted and enforced in accordance
        with the laws of Federal Republic of Nigeria applicable to contracts
        entered into and executed in Nigeria.
      </p>
      <br />
      <br />
      <strong>4.7 Binding Arbitration</strong>
      <br />
      <br />
      <p>
        PLEASE READ THIS PROVISION ON "BINDING ARBITRATION" CAREFULLY. IN THE
        EVENT OF A DISPUTE WITH RAMP, IT LIMITS YOUR RIGHTS.
      </p>
      <br />
      <br />
      <p>
        Unless your Dispute is subject to an exemption to this agreement to
        arbitrate set out below, you and Evea agree that any and all past,
        present, and future Disputes will be resolved by arbitration. You and
        Evea further agree that any arbitration under this Section shall not
        proceed as a class, group or representative
      </p>
      <br />
      <p>
        action. The arbitrator's decision can be enforced in any court with
        jurisdiction.
      </p>
      <br />
      <br />
      <p>
        Evea desires to resolve your concerns without resorting to formal legal
        action. You agree to try to resolve the Dispute informally before filing
        a claim against Evea by notifying Evea of the actual or possible Dispute
        in writing. Likewise, Evea will also provide you with notice of any real
        or potential Dispute in order to attempt to resolve any claim we may
        have informally before taking any official action.
      </p>
      <br />
   
      <p>
        The party providing the notice of the actual or possible Dispute (the
        "Notifying Party") will include the name of Company and the Notifying
        Party's contact information for any communications pertaining to the
        Dispute in the notice (a "Notice of Dispute"), and enough information
        about the Dispute to allow the other
      </p>
      <br />
      <p>
        party (the "Notified Party") to comprehend and evaluate the concerns
        made. If the Notified Party responds within ten (10) business days of
        receiving the Notice of Dispute, that it is ready and prepared to engage
        in good faith discussions in order to settle the Dispute informally,
        then each party shall immediately take part in such discussions in good
        faith.
      </p>
      <p>
        If a Dispute is not resolved within 30 days after the Notice of Dispute
        is sent (or if the Notified Party fails to respond to the Notice of
        Dispute within ten (10) business days), the Notifying Party may initiate
        an arbitration procedure as described below. If any party attempts to
        initiate arbitration without first delivering a Notice of Dispute and
        otherwise fulfilling all of the responsibilities set out in the
        preceding paragraph, the arbitration will be cancelled, and the claim
        will be dismissed with prejudice by the arbitrator(s), awarding the
        other party all of the costs and expenses (including, but not limited
        to, reasonable legal fees) incurred in relation with the Dispute.
      </p>
      <b>Arbitration procedure.</b>
      <p>
        In the event you and Evea could not resolve any Disputes informally as
        described above, You and Evea both agree to arbitrate, to resolve a
        Dispute through final and binding arbitration pursuant to the terms of
        this section.
      </p>
      <p>
        You and Evea agree that the arbitration will be conducted under the
        Nigerian ‘Arbitration and Conciliation Act’ ("ACA") and its Arbitration
        Rules (the "Rules"). A party that wishes to initiate arbitration must
        submit a formal written Demand for Arbitration to the other party, as
        stipulated in the ACA Rules.
      </p>
      <p>
        Arbitration will proceed on an individual basis before a single
        arbitrator; The single arbitrator will be chosen by the parties from the
        ACA's registry of arbitrators and will be either a retired judge or an
        attorney licensed to practice law with expertise arbitrating or
        mediating conflicts. If the parties cannot agree on an arbitrator within
        fifteen (15) days of delivery of the Demand for Arbitration, then the
        ACA will appoint the arbitrator in accordance with the ACA Rules.
      </p>
      <p>
        You and Evea further agree that the arbitration proceeding will be held
        in the English language and shall take place in Lagos, Nigeria, or, if
        you so choose, all proceedings can be conducted via videoconference,
        telephony or via other remote electronic means. Filing costs and
        administrative fees shall be paid in
      </p>
      <p>
        accordance with the ACA Rules; Each party is responsible for its own
        legal fees and out-of-pocket expenses incurred in connection with the
        arbitration, except forthe appropriate apportionment of any
        administrative fees and expenses or arbitrator fees and expenses
        associated with the arbitration which shall be determined by the
        arbitrator in the arbitration award. The award of the arbitrator shall
        be final and binding, and judgment on the award may be entered in any
        court of competent jurisdiction. The arbitral award must be in writing
        and signed by the arbitrator, according to Section 26 of the ACA. It
        must also provide the grounds for the ruling, as well as the date and
        location of the arbitration. In Nigeria, an arbitral award is final and
        cannot be appealed.
      </p>
      <p>
        The arbitrator must take reasonable precautions to protect sensitive and
        confidential information. The arbitration proceedings, as well as any
        information relating to them, shall be kept confidential, including the
        nature and details of the Dispute arbitrated, evidence produced,
        testimony given, and the arbitration outcome, unless such information
        was already in the public domain or was obtained independent from the
        Dispute. Unless compelled by law, Company and Evea, as well as any
        witnesses, advisors, and arbitrators, will only communicate/share
        information that is necessary to prepare for or conduct arbitration or
        other legal procedures, or to enforce the outcome of the same.
      </p>
      <p>
        Prohibition of Collective Arbitration – Class and Representative
        Actions.
      </p>
      <p>
        You and Evea agree that, regardless of what the Rules say, any Dispute
        will be arbitrated on an individual basis; For no arbitration shall
        proceed on a class, group, representative, collective, or coordinated
        basis, even if the arbitration claim or claims had been asserted or
        could have been asserted in court on a purported class, group,
        representative, collective, or coordinated basis. And you and Evea agree
        that, you and Evea are not permitted to file a claim as part of a class,
        group, representative, collective, coordinated, consolidated, or mass
        arbitration/litigation (each, a "Collective Arbitration"); Unless all
        Parties to the
      </p>
      <p>
        arbitration consent in writing: (a) No Party to the arbitration shall
        join, consolidate, or otherwise bring claims for or on behalf of two or
        more persons or entities in the same arbitration unless persons or
        entities are parties to a single transaction, And (b) An award in
        arbitration shall determine the rights and obligations of the named
        parties only, and only with respect to the claims in arbitration, and
        shall not determine the rights, obligations, or interests of anyone
        other than a named party, or resolve any claim of anyone other than a
        named party; nor make an award for the benefit of, or against, anyone
        other than a named party. Any attempt by the arbitrator to dismiss,
        waive, revise, modify, or fail to enforce this provision, whether
        through a rule, policy, arbitration decision, or other means, shall be
        nullified and unenforceable. And any challenge to the validity of this
        provision must be decided solely by a law court of competent
        jurisdiction (in Lagos, Nigeria), not by ACA or any other arbitrator.
      </p>
      <p>Severability.</p>
      <p>
        If any element of this Binding Arbitration provision, excluding the
        Prohibition of Collective Arbitration, is found to be unlawful or
        unenforceable by a court, then the remaining portions of this section
        shall remain valid and enforceable. In the event that a court finds any
        part of the Prohibition of Collective Arbitration provisions to be
        invalid or unenforceable for any reason, and that judgment or decision
        is not overturned after any rights to appeal have been exhausted, then
        any claim that cannot be arbitrated in accordance with the Prohibition
        of Collective Arbitration provisions will be invalid or unenforceable
        and must be severed
      </p>
      <br />
      <p>from arbitration and litigated in court.</p>
      <p>Future Amendments to this Binding Arbitration Provision.</p>
      <p>
        You and Evea agrees that any future amendments to this Binding
        Arbitration provision will not apply to any claim that was filed (in a
        legal proceeding against us) before the amendment's effective date. On
        and after the amendment’s effective date, the amendment shall apply to
        any and all subsequent disputes or claims between you and Evea, governed
        or regulated by this Binding Arbitration provision.
      </p>
      <p>
        Evea shall notify the Company’s Evea Account Natural Person Applicant
        and/or Administrator(s) via email of any amendments to this Binding
        Arbitration provision; the amended terms will be posted on Evea’s
        website at least 30 days before the amended terms take effect. You may
        terminate your Evea Account within the 30-day period if you do not agree
        to the amended terms, and you will not be bound by the amended terms.
      </p>
      <p>Procedure to Opt-Out of the Binding Arbitration Section.</p>
      <p>
        You may opt-out of this Binding Arbitration section by writing to Evea
        Inc. about your desire to opt-out, to this address: 23, Kola Amodu
        Crescent, Magodo Phase 2, Lagos State, Nigeria.
      </p>
      <p>
        Within thirty (30) days of accepting this Platform Agreement for the
        first time, you must send the opt-out letter via postal mail only, to
        the specified address, and you are also responsible for keeping records
        to show that the opt-out letter was submitted within the specified time
        frame (such as courier receipt showing the date of the delivery or the
        date courier received the opt-out letter for delivery).
      </p>
      <p>
        The opt-out letter must include your Company's name, a statement that
        your Company is opting out of the Platform Agreement's Binding
        Arbitration provision, and the name and signature of person authorized
        by Company to opt the Company out of Binding Arbitration provision. Any
        past, alternative, or future arbitration agreements or dispute
        resolution agreements/arrangements that you may have with us or third
        parties will be unaffected if you choose to opt out of this Binding
        Arbitration section. All other provisions of the Platform Agreement
        shall remain in effect if you opt out of this Binding Arbitration
        section.
      </p>
      <b>4.8 Legal Process</b>
      <p>
        Any legal order we receive connected to your use of the Services, such
        as subpoenas, warrants, or liens, may be responded to and compiled with.
        We are not liable for any losses you may incur as a result of our
        compliance to such a judicial order. We may take whatever steps we feel
        are necessary to comply with legal
      </p>
      <br />
      <p>
        orders, such as retaining funds or suspending, terminating, closing, or
        limiting access to your Evea Account; or disclosing information in our
        possession as requested by the legal order's issuer. Where permitted, we
        shall give you sufficient notice that such an order has been obtained.
        Any court order or legal procedure concerning you or your Evea Account
        does not obligate us to contest or appeal it.
      </p>
      <b>4.9 Modifications/Updates to this Platform Agreement</b>
      <br />
      <p>
        We retain the right to update/modify this Platform Agreement at any time
        by posting the update version of this Platform Agreement (including the
        revision's date) on the legal agreement webpage of the Evea website.
        Unless otherwise stated, the updated version will take effect at the
        time it is posted. Notification via email will be sent to you concerning
        the updated Platform Agreement; It is your duty as an Administrator to
        keep the contact information linked with your Evea Account up-to-date
        and accurate, including any email addresses. You are responsible for
        informing Users of any applicable revisions to the Platform Agreement
        and all terms, agreements, and policies incorporated by reference, as
        well as ensuring that Users follow those updates. Users may or may not
        receive direct notice of modification of the Platform Agreement.
      </p>
      <br />
      <p>
        In urgent circumstances, such as when necessary to comply with relevant
        law, regulation, or Payment/Card Network rules, or to avoid or mitigate
        any major risk, loss, or damage, the updated Platform Agreement may take
        effect immediately. If you do not agree to any modifications or
        amendments in the Platform
      </p>
      <br />
      <p>
        Agreement, you must terminate your Evea Account in accordance with this
        Platform Agreement. You will be deemed to have consented to the
        updated/modified Platform Agreement if you continue to use the Services
        or Cards after any such modification takes effect as indicated in the
        applicable Notice.
      </p>
      <p>
        Except for revisions/update/modifications made in accordance with the
        terms of this Platform Agreement, the only means to alter or otherwise
        modify this Platform Agreement is by a written agreement signed by an
        authorized representative of Evea and an authorized representative of
        Company.
      </p>
      <br />
      <p>
        Any exemption, modification, or indulgence that we grant Company, in any
        form or at any time, applies only to the specific instance in question
        and does not constitute a general waiver or exemption, modification, or
        indulgence under this Platform Agreement for any other or future acts,
        events, or conditions. Also, any delay by Evea in pursuing our rights
        under this Platform Agreement does not imply that those rights have been
        waived or forfeited.
      </p>
      <br />
      <br />
      <b>4.6 Entire Agreement</b>
      <p>
        The entire understanding between Company and Evea regarding the subject
        matter of this Platform Agreement, including any terms, agreements, or
        policies incorporated by reference, is set out in this Platform
        Agreement; and no other agreements, representations, or warranties
        (other than those provided in this Platform Agreement and the applicable
        Terms and Conditions) will be valid/binding unless they are in writing
        and signed by Company and Evea.
      </p>
      <br />
      <b>4.6 Severability</b>
      <p>
        If a court of competent jurisdiction finds any term of this Platform
        Agreement to be defective/invalid or unenforceable, that provision will
        be entirely severable, construed and enforced in a manner that as
        closely as possible reflects the parties' original intentions and is
        consistent with applicable law, and the remaining
      </p>
      <br />
      <p>
        provisions of this Platform Agreement will continue to be in full force
        and effect.
      </p>
      <br />
      <b>5. Defined Terms</b>
      <br />
      <p>
        The following are the definitions of certain key words used in this
        Platform Agreement:
      </p>
      <br />
      <p>
        • Additional Terms means additional terms or policies to which you may
        be required to agree if we introduce new products, features,
        integrations, promotions, or rewards, or otherwise increase the breadth
        and quality of the Services.
      </p>
      <br />
      <p>
        • Administrator means an individual(s) selected by Company with the
        necessary capacity and ability to do business, manage Company's Evea
        Account, and act on Company's behalf, including consenting to this
        Platform Agreement.
      </p>
      <br />
      <p>
        • Beneficial Owner means someone who holds 25% or more of the Company's
        equity holdings, whether directly or indirectly.
      </p>
      <br />
      <p>
        • Beta Services means any products or services labeled as beta,
        pre-release or similar, which may contain features and functionality
        that are incomplete and are subject to significant modification or
        discontinuation.
      </p>
      <br />
      <p>
        • Card Networks means the payment card networks includes Verve, Visa or
        Mastercard.
      </p>
      <br />
      <p>
        • Card Partner means a bank, financial institution, or other partner
        that offers services directly connected to one or more cards services.
      </p>
      <br />
      <p>
        • Cards means physical or virtual payment cards issued by a Financial
        Institution Partner and managed through your Evea Account.
      </p>
      <br />
      <p>
        • Charge (or transaction) means a payment for goods or services made
        with an Evea Card to a merchant that accepts payments on the applicable
        Card Network.
      </p>
      <br />
      <p>
        • Chargeback means a dispute that you initiate against a merchant over
        an unresolved issue with the merchant or because a Charge or transaction
        was unauthorized.
      </p>
      =
      <br />
      <p>
        • Company Data means any information or documents submitted to Evea by
        the Company, including Financial Data and any Personal Data provided by
        the Company, Administrators, or Users.
      </p>
      <br />
      <p>
        • Control Person means individual with significant responsibility to
        control, manage, or direct Company.
      </p>
      <p>
        • Credentials means usernames, passwords, and other identifiers or
        credentials used to securely identify and authenticate you when using
        the Services or logging into your Evea Account.
      </p>
      <br />
      <p>
        • Dispute means any past, present, or future claim, dispute, or
        controversy between you (or persons claiming through or connected with
        you) and us (or persons claiming through or connected with us)
        pertaining to or arising out of the Platform Agreement, certain
        Services, any application for an Evea Account, and/or the activities or
        relationships that involve, give rise to, or arise from any of the
        foregoing.
      </p>
      <br />
      <p>
        • Evea Account means your Company’s account(s) with Evea that is/are
        used to access the Services.
      </p>
      <br />
      <p>
        • Evea Data means all data developed or acquired by Evea through the
        creation or provision of Services, Cards, or Third-Party Services, as
        well as data generated or recorded by the Evea platform, but excludes
        Company Data.
      </p>
      <br />
      <p>
        • ‘Evea’ or ‘we’ or ‘us’ means Evea Incorporation and its affiliates,
        unless otherwise specified.
      </p>
      <br />
      <p>
        • Evea Property means the Services and related technology; Evea Data;
        and copyrights, patents, trade secrets, trade or service marks,
        trademarks, logos, and any intellectual property encompassing or
        integrated into each of the aforementioned.
      </p>
      <p>
        • Feedback means any and all feedback, suggestions, ideas, or
        improvement requests you submit to us.
      </p>
      <br />
      <p>
        • Fees means charges/costs we levy on you for using our Services or
        having an Evea Account.
      </p>
      <br />
      <p>
        • Financial Data means Company’s bank balance, transaction, account, or
        other information that are accessible to Evea through Linked Accounts
        and Third-Party Services.
      </p>
      <br />
      <p>
        • Fines means any fines, penalties, or other charges imposed by Evea, a
        card Partner, governmental agency or regulatory authority as a result of
        your violation of this Platform Agreement, any of the Terms and
        Conditions, or other agreements you have with Evea or it’s card Partner.
      </p>
      <p>
        • Issuer means the bank or financial Institution that is a member of the
        Card Network (which is indicated on Cards) and is responsible for
        issuing the Cards to you.
      </p>
      <br />
      <p>
        • Linked Account means any bank account that is held with a financial
        institution that is linked to your Evea Account or authorized for
        payment of credit card Charges, Fees, and Fines.
      </p>
      <br />
      <p>
        • Natural Person Applicant means the individual who apply for an Evea
        Account on behalf of the Company, its also known as the Administrator of
        the Company’s Evea Account.
      </p>
      <br />
      <br />
      <p>
        • Notice means any physical, voice/verbal, or electronic communication,
        as well as legal notices, given or directed to Company, Users, or
        Administrators via phone call, text or SMS, email, push notification
        through Company’s Evea Account, or by any other means.
      </p>
      <br />
      <p>
        • Personal Data means data or information that identifies a natural
        person.
      </p>
      <br />
      <p>
        • Platform Agreement means this Platform Agreement, as amended, as well
        as any other terms, agreements, or policies referred to in it, such as
        the Terms and Conditions specific to the Services you use, the Privacy
        Policy, Card Terms, Rewards Terms, and any additional terms or
        supplemental agreements.
      </p>
      <br />
      <p>
        • Prohibited Activities means the type of businesses and activities that
        are prohibited from using Evea’s Services, list of Prohibited businesses
        is posted on our website and are updated on a regular basis.
      </p>
      <br />
      <p>
        • Prohibited Person means any individual or organization that is subject
        to sanctions in Nigeria, has been identified on any EFCC or ICPC list,
        or is subject to any law, regulation, or other list of any government
        agency that prohibits or restricts us from providing an Evea Account or
        Services to such person/organization or from otherwise conducting
        business with such person or organization.
      </p>
      <br />
      <p>
        • Restricted Activities means the type of businesses and activities that
        needs to provide more additional information or document before getting
        access or using Evea’s Services, lists of Restricted businesses are
        posted on our website and are updated on a regular basis.
      </p>
      <br />
      <p>
        • Security Practices means certain procedures, methods and controls
        designed to assist, safeguard and protect your Evea Account and data
        against fraud, theft, and misuse.
      </p>
      <br />
      <p>
        • Services means the financial products, technology, expense management,
        spend management (budgeting), card payment services, integrations with
        Third-Party Services, and all other Evea services, including those
        accessible through your Evea Account.
      </p>
      <br />
      <p>
        • Terms and Condition means the terms and agreements that apply to
        certain aspects of the Services, including the Card Terms, Partner
        terms, and any other terms and conditions that govern access to and use
        of any of Evea’s Services.
      </p>
      <br />
      <p>
        • Third-Party Service Provider means an affiliate or other third party
        that assists us in providing the Services to you, supports our internal
        operations, or offers other services linked to, connected to, or
        delivered via the Services or an Evea Account.
      </p>
      <br />
      <p>
        • Third-Party Services means services and data provided by third parties
        in relation to or provided through the Services. The Third-Party
        Services include but are not limited to payment processors, Card
        network, debt recovery processor (such as Recova–AppZone) and
        applications used to monitor Linked Accounts (such as Okra).
      </p>
      <br />
      <p>
        • Users means Administrators, any employees, contractors, or agents
        authorized to use the Services on behalf of the Company.
      </p>
      <br />
    </div>
  );
};

export default Platform;
