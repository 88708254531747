import React from "react";
import "../scss/components/Footer.scss";
import Logo from "../assets/images/Logo.png";
import { Link } from "react-router-dom";
import Insta from "../assets/icons/instagram.svg";
import Linked from "../assets/icons/linkedin.svg";
import Twitter from "../assets/icons/twitter.svg";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__bg">
        <div className="footer__container">
          <div className="footer__wrapper">
            <div>
              <div className="footer__logo">
                <Link to="/">
                  <img src={Logo} alt="Logo" />
                </Link>
              </div>

              <h3>Built for founders and their startups & young businesses.</h3>
            </div>
            <div data-aos="fade-up">
              <p>Product</p>
              <ul>
                <Link to="/business-card" className="footer__link">
                  <li>Credit Card</li>
                </Link>

                <Link to="/spend-management" className="footer__link">
                  {" "}
                  <li>
                    Spend Management <span>Coming soon</span>{" "}
                  </li>{" "}
                </Link>

                <Link to="/expense-management" className="footer__link">
                  <li>Expense Management</li>
                </Link>
              </ul>
            </div>
            <div data-aos="fade-down">
              <p>Company</p>
              <ul>
                <Link to="/about-us" className="footer__link">
                  <li>About Evea</li>
                </Link>
                <Link to="/work-with-us" className="footer__link">
                  <li>Careers</li>
                </Link>

                <Link to="/contact-us" className="footer__link">
                  <li>Contact Us</li>
                </Link>
              </ul>
            </div>
            <div data-aos="fade-up">
              <p>Features</p>
              <ul>
                <Link to="/virtual-cards" className="footer__link">
                  <li>Virtual Cards</li>
                </Link>

                <li>
                  Account Integration <span>Coming soon</span>
                </li>
                <li>
                  Mobile App <span>Coming soon</span>
                </li>
                <Link to="/pricing" className="footer__link">
                  <li>Pricing</li>
                </Link>

                <li>
                  Reward <span>Coming soon</span>
                </li>
              </ul>
            </div>
            <div data-aos="fade-down">
              <p>Resources</p>
              <ul>
                <li>Help Center</li>
                <li>Blog</li>
                <li>
                  Partners <span>Coming soon</span>
                </li>
                <Link to="/referral" className="footer__link">
                  <li>Referrals</li>
                </Link>

                <li>Privacy Policy</li>
                <li>Legal Agreement</li>
              </ul>
            </div>
            <div>
              <p>Join the Community</p>
              <div>
                <img src={Insta} alt="" />
                <img src={Linked} alt="" />
                <img src={Twitter} alt="" />
              </div>
            </div>
          </div>
          <div className="footer__footer">
            <p>
              © 2022 Evea Inc. “Evea,” "Evea Financial" and the Evea logo are
              registered trademarks of the company | RC1886991. Evea products
              may not be available to all customers. Terms and conditions apply
              and are subject to change. The Evea Corporate Card is issued by
              Sudo, Licensed and Regulated by the Central Bank of Nigeria (CBN).{" "}
              <br />  <a href="/">Visit our Terms of Service </a>  for details. <a href="/privacy">Read our Privacy
              Policy</a>   
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
