
import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header1'
import ScrollToTopp from '../components/ScrollToTopp'

const WebLayout1 = () => {
  return (
    <div>
      <ScrollToTopp />
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

export default WebLayout1