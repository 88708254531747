import React from "react";
import "../scss/AboutUs.scss";

import Genesys from "../assets/icons/AboutUs/icons/genesys.svg";
import Zeri from "../assets/icons/AboutUs/icons/zeri.svg";
import Niche from "../assets/icons/AboutUs/icons/niche.svg";
import ArrowRight from "../assets/icons/AboutUs/icons/arrow-right.svg";
import StartUs from "../assets/icons/AboutUs/icons/startus.svg";
import Mark1 from "../assets/icons/Pricing/mark1.svg";

const AboutUs = () => {
  return (
    <div className="aboutus">
      <div className="aboutus__wrapper">
        <section className="aboutus__hero">
          <p>About Us</p>
          <p>
            We're redefining B2B financial services for the next generation of
            founders and growing businesses.
          </p>
        </section>
        <section className="aboutus__content">
          <p className="aboutus__content__left">The Story</p>
          <div className="aboutus__content__right">
            <p>
              The possibilities of one’s achievements should only be restricted
              by how big you are able to dream. However, in today’s world, as
              one is building a startup by forging new pathways and defying
              convention, it’s quite difficult to get access to the funding you
              want, and whenever you want it, as you’re been held back by
              financial institutions that are comfortable with the status quo.
            </p>
            <p>
              Evea, on the other hand, envisions a future in which business
              possibilities and advancements are never impeded or delayed by
              financing, and time is never wasted; for businesses should be able
              to simply and quickly access the financial services they require.
            </p>
            <p>
              Hence, we’re providing access to expeditious finance to grow
              businesses via issuance of credit cards and business management
              system thereby empowering their dreams.
            </p>
            <p>
              We say, keep dreaming big, go on growing, rise to the tasks; for
              the dream of ours is serving the driven.
            </p>
          </div>
        </section>
        <section className="aboutus__content">
          <p className="aboutus__content__left">Built on the Principle of</p>
          <div className="aboutus__content__right">
            <div className="aboutus__content__principle">
              <img src={Mark1} alt="Check" />
              <p>Simplicity Privacy</p>
            </div>
            <div className="aboutus__content__principle">
              <img src={Mark1} alt="Check" />
              <p>Transparency, and</p>
            </div>
            <div className="aboutus__content__principle">
              <img src={Mark1} alt="Check" />
              <p>Privacy</p>
            </div>
          </div>
        </section>

        <section className="aboutus__content">
          <p className="aboutus__content__left">Our customers</p>
          <div className="aboutus__content__right">
            <p>
              Hundreds of firms are utilizing Evea to float/support their
              operational funds. We are delighted to assist founders and their
              teams in keeping focused on what they are building and
              enthusiastic about, whether they are freshly formed startups,
              existing tech businesses, ecommerce, logistics, fashion brands, or
              the likes, no matter the industry they operate in, we are here to
              support the dreams of founders.
            </p>
            <a href="/" className="aboutus__content__link">
              <p>Our Customers</p>
              <img src={ArrowRight} alt="ArrowRight" />
            </a>

            {/* <div>
              <div className="aboutus__content__icons">
                <img src={Gallery} alt="icon" />
                <img src={Gallery} alt="icon" />
                <img src={Gallery} alt="icon" />
              </div>
            </div> */}
          </div>
        </section>

        <section className="aboutus__content">
          <p className="aboutus__content__left">Our investors</p>
          <div className="aboutus__content__right">
            <p>
              Genesys Hub, Niche Capital, and Zeri Capital are among the
              investors that financed Evea. Having raised more than $110k in
              equity.
            </p>
            <div className="aboutus__content__investors">
              <img src={Genesys} alt="Genesys" />
              <img src={Niche} alt="Niche Capital" />
              <img src={Zeri} alt="Zeri Capital" />
            </div>
          </div>
        </section>
        <section className="aboutus__content">
          <p className="aboutus__content__left">Let’s build together</p>
          <div className="aboutus__content__right">
            <p>
              Interested in working for a mission-driven, innovative fintech
              startup that is changing the way financial services are delivered?
              We want to hear from you if you are passionate about
              revolutionizing an industry, transforming lives, and being a part
              of something greater than yourself.
            </p>
            <a href="/work-with-us" className="aboutus__content__link">
              <p>See open roles</p>
              <img src={ArrowRight} alt="ArrowRight" />
            </a>
          </div>
        </section>

        <section className="aboutus__content">
          <p className="aboutus__content__left">Press</p>
          <div className="aboutus__content__right">
            <div>
              <p>
                Send an email to <a href="/">press@evea.africa </a> to contact
                our public relations team or to request a media kit.
              </p>
              <div className="aboutus__content__press">
                <img src={StartUs} alt="startup" />

                <p>
                  Want to level-up your #financial business? Discover the top 5
                  #startups offering innovative #CreditCard solutions and
                  explore how you can use them to your advantage.
                </p>
              </div>
              <a href="https://www.startus-insights.com/innovators-guide/credit-card-solutions/" className="aboutus__content__link" target="_blank" rel="noreferrer">
                <p>Read More</p>
                <img src={ArrowRight} alt="ArrowRight" />
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
