import React from "react";
import "../scss/Referral.scss";
import ArrowDown from "../assets/icons/arrow-down.svg";
import PrimaryInput from "../components/PrimaryInput";
import ArrowRight from "../assets/icons/AboutUs/icons/arrow-right.svg";

const Referral = () => {
  return (
    <div className="referral">
      <div className="referral__wrapper">
        <section className="referral__hero">
          <h1>Love Evea? </h1>
          <h1> Refer a friend, a founder.</h1>
          <div>
            <p>Submit a referral</p>
            <img src={ArrowDown} alt="ArrowDown" />
          </div>
        </section>

        <div className="referral__formContainer">
          <section className="referral__form">
            <div className="referral__formTop">
              <div className="referral__formTop__left">
                <p>The Referral Details</p>
                <img src={ArrowRight} alt="ArrowRight" />
              </div>
              <div className="referral__formTop__right">
                <PrimaryInput
                  type="text"
                  placeholder="Enter Full Name"
                  label="First name, Last Name"
                />
                <PrimaryInput
                  type="email"
                  placeholder="Enter Email Address"
                  label="Email address"
                />
                <PrimaryInput
                  type="text"
                  placeholder="Enter Company Name"
                  label="Company Name"
                />
                <PrimaryInput
                  type="phone"
                  placeholder="Enter Phone Number"
                  label="Phone"
                />
                <PrimaryInput
                  type="text"
                  placeholder="Enter Number of Employees"
                  label="No. of Employees"
                />

                <div>
                  <label for="">Message</label>
                  <textarea rows="8" />
                </div>
              </div>
            </div>

            <div className="referral__formBottom">
              <div className="referral__formBottom__right">
                <PrimaryInput
                  type="text"
                  placeholder="Enter Full Name"
                  label="First name, Last Name"
                />
                <PrimaryInput
                  type="email"
                  placeholder="Enter Email Address"
                  label="Email address"
                />
                <PrimaryInput
                  type="text"
                  placeholder="Enter Company Name"
                  label="Company Name"
                />
                <PrimaryInput
                  type="phone"
                  placeholder="Enter Phone Number"
                  label="Phone"
                />
                {/* <PrimaryInput
                type="text"
                placeholder="Enter Number of Employees"
                label="No. of Employees"
              /> */}
              </div>
              <div className="referral__formBottom__left">
                <img
                  src={ArrowRight}
                  alt="ArrowRight"
                  className="referral__form__arrow"
                />
                <p>Your details</p>
              </div>
            </div>
          </section>
          <div>
            <div></div>
            <button>Send</button>
          </div>
        </div>

        <div className="referral__button"></div>
      </div>
    </div>
  );
};

export default Referral;
