import React from "react";
import "../../scss/components/Faq.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function VirtualFaq() {
  return (
    <div>
      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is a virtual card?</Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <div>
              <p>
                A virtual payment card, sometimes called a temporary card number
                or pseudo card number, is a unique 16-digit (credit or debit)
                card number that’s digitally-generated and tied to your account.
                it’s basically utilized for online purchases/payments, thereby
                keeping your real card number protected. To avoid getting
                overcharged, you may frequently establish a maximum spend or
                charge limit on your virtual card. Some virtual cards will also
                lock to a merchant, preventing the card from being used anywhere
                else if the merchant is hacked.
              </p>
            </div>
          </>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography> How do virtual payment cards work?</Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              Between your funding source and the merchant, virtual cards
              function as a layer of protection. They pass any authorized and
              completed transactions to your funding source directly (including
              refunds). These cards can protect you from data breaches at your
              favorite merchants/stores, merchant overcharges, and grey market
              subscription scams. They're ideal for usage on popular websites
              with recurring payments.
            </p>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>
            What security measures does a virtual card provider take?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              As a virtual card provider, safety and security are crucial. We go
              through extensive internal and third-party audits before issuing a
              virtual payment card. Furthermore, virtual card providers must
              adhere to the same stringent PCI-DSS (Payment Card Industry Data
              Security Standard) standards and guidelines as your bank or credit
              union.
            </p>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>What types of virtual cards are obtainable?</Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              Burner cards and subscription cards are the two forms of virtual
              cards available. Subscription cards are used for regular payments
              and burner cards are used for one-time transactions. You can make
              as many burner cards and subscription cards as you like with Evea.
            </p>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>When should a business utilize virtual cards?</Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              When shopping online, making a one-time payment or purchase, or
              paying for subscriptions, businesses should use virtual cards.
            </p>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>Can virtual credit-cards be used in-store?</Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              Virtual cards are intended for online use, but they can be used at
              businesses or outlets that accept card numbers manually.
            </p>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>
            {" "}
            The advantage of using virtual cards for businesses?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              Because virtual cards safeguard the original account details from
              being used, they are safer than physical cards. It provides more
              control over spending and makes it easier to detect and halt
              vendor fraud.
            </p>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7a-content"
          id="panel7a-header"
        >
          <Typography>
            {" "}
            How might virtual cards be used by businesses to manage
            subscriptions?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              Business can create a distinct virtual card for each subscription,
              limiting the amount that can be charged and specifying when the
              subscription should terminate. That means vendors can't overcharge
              or automatically renew your membership unless you give them the
              permission.
            </p>
          </>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
