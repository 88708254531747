import React from "react";
import "../scss/Pricing.scss";
import Mark from "../assets/icons/Pricing/mark.svg";
import Mark1 from "../assets/icons/Pricing/mark1.svg";
import eveaCard from '../assets/images/eveaSlantCard.png'

const Pricing = () => {
  return (
    <div className="pricing">
      <div className="pricing__wrapper">
        <h1>
          The corporate card <br />
        </h1>
      </div>

      <div className="pricing__middle">
        <div>
          <div>
            <p>Unlimited virtual & physical cards.</p>{" "}
            <img src={Mark} alt="mark" />
          </div>
          <div>
            <p>Expense management.</p>
            <img src={Mark} alt="mark" />
          </div>
          <div>
            <p>Smart limits & spend controls.</p>
            <img src={Mark} alt="mark" />
          </div>
          <div>
            <p>Automated receipt matching.</p>
            <img src={Mark} alt="mark" />
          </div>
        </div>

        <div>
          <div>
            {" "}
            <p>
              Unlimited Users (Teams). <span>Coming soon</span>
            </p>
          </div>
          <div>
            {" "}
            <p>
              Accounting software integrations. <span>Coming soon</span>
            </p>
          </div>
          <div>
            {" "}
            <p>
              Spend management system (Budgeting). <span>Coming soon</span>
            </p>
          </div>
          <div>
            {" "}
            <p>
              Robust expense policies & approvals. <span>Coming soon</span>
            </p>
          </div>
        </div>
      </div>

      <div className="pricing__extra">
            <div className="">
                <img className="pricing__extra__cardImg" src={eveaCard} />
            </div>
            <div>
               <div>
                  <img className="pricing__extra__mark" src={Mark} alt="mark" />
                  <p>Transaction fees = 3% per transaction</p>{" "}
               </div> 
               <div>
                  <img className="pricing__extra__mark" src={Mark} alt="mark" />
                  <p>ATM withdrawals fees = 5% per transaction</p>{" "}
               </div> 
               <div>
                  <img className="pricing__extra__mark" src={Mark} alt="mark" />
                  <p>Monthly card fees = ₦3000.00 per card/month <span className="pricing__extra__span">( first(1) card is free )</span></p>{" "}
               </div> 
            </div>
      </div>

      <div className="pricing__form">
        <div className="pricing__formContainer">
          <div className="pricing__formWrapper">
            <input type="text" placeholder="Enter your work email" />
            <button type="submit">Get Started</button>
          </div>
          <div>
            <img src={Mark1} alt="mark" />
            <p>No personal guarantee, with up to 10x higher limits...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
