import React from "react";
import "../../scss/components/Faq.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function LandingFaq() {
  return (
    <div>
      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Who is eliigible to apply?</Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <div>
              <p>
                Evea is only designed for companies registered in Nigeria (for
                now).
              </p>
              <p>
                Business such as sole proprietors (Business Name), Private
                Limited Company (LLC/LTD), are eligible to us Evea.
              </p>
              <p>
                Consumers, a mom-and-pop shop, and other unregistered businesses
                are ineligible.
              </p>
              <b>
                Also, there are certain businesses we cannot support at Evea:
              </b>
              <p>
                Companies involved in the following businesses activitivities
                are not to use Evea:
              </p>
              <ul>
                <li>Gambling, betting, lotteries, etc. any games of chance.</li>
                <li>
                  Multi-level marketing, Ponzi scheme, pyramid scheme, etc.
                </li>
                <li>Escort services/Pornographic businesses</li>
                <li>
                  Production sale, or disribution of Hard drugs, such as
                  marijuana, etc.
                </li>
                <li>“Gray market” goods or services</li>
              </ul>
            </div>
          </>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Will my personal credit score be affected?</Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <p>
            No. Your personal credit score isn’t affected by applying & using
            the Evea Card; We only review your business financial data, and
            using Evea will help boost your business credit score.
          </p>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="pane3a-content"
          id="panel3a-header"
        >
          <Typography>What are the payment terms?</Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <div>
              <p>
                The Evea credit card is based on monthly payments, your spending
                (in a given billing cycle) is aggregated into one monthly
                payment that is deducted automatically from your connected
                account.
              </p>
              <b>To better explain this:</b>
              <p>
                Each billing cycle is 30 days, once a cycle ends, you have a
                grace period of 28days extra to pay back (in full) all spending
                accrued on the card for that particular billing cycle (that
                ended).
              </p>
              <p>
                You can initiate the payment yourself anytime within the grace
                period (through the dashboard) but if payment is not received by
                the end of the grace period (which also coincide with the
                payment due-date), the said accrued debt will be deducted
                automatically from your linked account.
              </p>
            </div>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>
            What factors go into determining/setting my credit limit?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <div>
              <p>
                Credit limits are dynamic by design, and are determined based on
                your company’s real-time financial information, by evaluating
                your company’s cash balance, spending pattern, cashflow, credit
                history, etc.
              </p>
              <p>
                Because the credit limits are dynamic by design, Evea’s credit
                model updates in real time, so if a company depletes most of its
                cash in a given month, the credit limit on the card will be
                reduced until there’s more cash in the bank account; but as your
                business grows, your credit limit also increases.
              </p>
            </div>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>
            Evea cards generate revenue for the company in what way? Are there
            fees for using Evea card?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              There are no hidden fees or interest charges on your Evea card.
              Instead, we simply earn an interchange fee (from the merchant,
              through the card network) & transaction fee, for each purchase you
              make with your card.
            </p>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>Do I have to link my bank account? and, Why?</Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <div>
              <p>
                Yes, it’s actually important you link your active
                corporate/business bank account.
              </p>

              <p>
                Why? – In order to provide a card that doesn’t require an
                individual assurance/personal guarantee, we make use of your
                financial institution account data as an essential underwriting
                input.{" "}
              </p>

              <p>
                For this process to be successful and highly secured, Evea
                utilizes a third-party service provider (an outsider specialist
                enterprise) called <a href="/">Okra</a> to integrate with
                numerous banks in Nigeria. All data are highly encrypted by{" "}
                <a href="">Okra</a> to preserve high-level tiers of security{" "}
                <a href="/">(read here)</a> , and Evea itself NEVER stores your
                bank account login details/credentials, and they are NEVER
                visible to any Evea personnel/staffs.
              </p>
              <p>
                For more information or concerns, please reach us via:
                <a href="">help@evea.africa</a> or +234 812 651 6215
              </p>
            </div>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7a-content"
          id="panel7a-header"
        >
          <Typography>Who issues Evea Card?</Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              Sudo Africa issues the Card on the Verve and MasterCard Network.
            </p>
          </>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
