import React from "react";
import "../../scss/components/Privacy/Sidebar.scss";
import Logo from "../../assets/Logo/logo1.png";
import { NavLink, Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar__wrapper">
        <div className="sidebar__logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>

        <div>
          <p>Accountholder Agreements</p>
          <ul>
            <div>
              <NavLink to="/platform-agreement" activeClassName="active">
                <div></div>
                <li>Platform Agreement</li>
              </NavLink>
            </div>

            <div>
              <NavLink to="/terms-and-conditions" activeClassName="active">
                <div></div>
                <li>Card Terms & Conditions</li>
              </NavLink>
            </div>

            <div>
              <NavLink to="/" activeClassName="active">
                <div></div>
                <li>Cardholder [User] Agreement</li>
              </NavLink>
            </div>
          </ul>
        </div>

        <div>
          <p>Policies</p>
          <ul>
            <div>
              <NavLink to="/privacy" activeClassName="active">
                <div></div>
                <li>Privacy Policy</li>
              </NavLink>
            </div>

            <div>
              <NavLink to="/" activeClassName="active">
                <div></div>
                <li>AML</li>
              </NavLink>
            </div>
          </ul>
        </div>

        <div>
          <p>Partners</p>
          <ul>
            <div>
              <NavLink to="/" activeClassName="active">
                <div></div>
                <li>Sudo Card Agreement</li>
              </NavLink>
            </div>
          </ul>
        </div>

        <div>
          <p>Other Terms</p>
          <ul>
            <div>
              <NavLink to="/prohibited-activities" activeClassName="active">
                <div></div>
                <li>Prohibited and Resticted Activities</li>
              </NavLink>
            </div>

            <div>
              <NavLink to="/general-disclosures" activeClassName="active">
                <div></div>
                <li> General Disclosures</li>
              </NavLink>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
