import React from "react";

import "../scss/BusinessCard.scss";
import PrimaryButton from "../components/PrimaryButton";
import Quick from "../assets/icons/BusinessCard/quick.svg";
import Simple from "../assets/icons/BusinessCard/simple.svg";
import Dynamic from "../assets/icons/BusinessCard/dynamic.svg";
import Credit from "../assets/images/BusinessCard/credit.svg";
import Issue from "../assets/images/BusinessCard/issue.svg";
import Evea from "../assets/images/BusinessCard/evea.svg";
import Card from "../assets/images/BusinessCard/card.png";
import BusinessFaq from "../components/Faq/BusinessFaq";
import Mark1 from "../assets/icons/Pricing/mark1.svg";
import Scaling from "../components/Scaling";

const BusinessCard = () => {
  return (
    <>
      <div className="businessCard">
        <div className="businessCard__hero">
          <div className="businessCard__wrapper">
            <div className="businessCard__container">
              <div data-aos="fade-right">
                <h1>Credit card that is unique to your business.</h1>
                <p>
                  No matter how big or small your company is, Evea makes it
                  simple to get the funds you need. In a matter of minutes, you
                  may apply for a credit line and begin spending more wisely.
                </p>

                <PrimaryButton text="Get Started" />
                <div className="businessCard__heroIcon" data-aos="flip">
                  <img src={Mark1} alt="mark" />
                  <p>No personal guarantee, with up to 10x higher limits...</p>
                </div>
              </div>

              <div className="businessCard__heroCard" data-aos="flip-left">
                <img src={Card} alt="card" />
              </div>
            </div>
          </div>

          <section className="businessCard__cards">
            <div className="businessCard__cardsWrapper">
              <div data-aos="zoom-in">
                <img src={Quick} alt="Quick" />
                <h2>Quick Approval</h2>
                <p>
                  Get approved an adjustable credit line based on your cashflow
                  for businesses who qualify, allowing you to begin spending
                  right away.
                </p>
              </div>

              <div data-aos="zoom-in">
                <img src={Simple} alt="Simple" />
                <h2>Simple & quick online application</h2>
                <p>Applying for an Evea card simply takes few clicks.</p>
              </div>

              <div data-aos="zoom-in">
                <img src={Dynamic} alt="Dynamic" />
                <h2>Dynamic Credit.</h2>
                <p>
                  Credit limits that rise along with your business’s cash flow.
                  As your business grows, Evea develops alongside you.
                </p>
              </div>
            </div>
          </section>
        </div>

        <section className="businessCard__bg1">
          <section className="businessCard__credit">
            <img src={Credit} alt="Credit" data-aos="fade-right" />
            <div data-aos="fade-left">
              <h2>Credit flexibility is the key</h2>
              <p>
                With Evea, it's not just quick and easy to apply for your
                business financing; it's also versatile. Rather than accepting a
                limited number of businesses that fit a certain profile, Evea’s
                system examines each application individually and meet you where
                you are in your business.
              </p>
              <div>
                <img src={Mark1} alt="mark" />
                <p>Modern Underwriting (based on real-time data)</p>
              </div>
              <div>
                <img src={Mark1} alt="mark" />
                <p>Dynamic Credit line</p>
              </div>
            </div>
          </section>

          <section className="businessCard__credit">
            <div data-aos="fade-right">
              <h2>Issue and manage your cards</h2>
              <p>
                Upon approval, instantly issue an unlimited number of virtual
                cards and 1 physical card per user. Physical card(s) arrives in
                3 - 4 business days.
              </p>
              <p>
                Set spending limits on cards issued, as well as manage the use
                of the cards, either to freeze, unfreeze, or terminate card(s)
                as needed.
              </p>
            </div>
            <img src={Issue} alt="Issue" data-aos="fade-left" />
          </section>
        </section>

        <section className="businessCard__eveaSection">
          <div className="businessCard__eveaSection__container">
            <div className="businessCard__eveaSection__top">
              <div>
                <p>Why you should choose us.</p>
              </div>

              <h1>Evea vs. other business credit cards.</h1>
            </div>

            <div className="businessCard__evea">
              <img src={Evea} alt="Evea" data-aos="flip-left" />

              <div data-aos="zoom-in">
                <p>Evea</p>
                <ul>
                  <li>No annual/hidden fees</li>
                  <li>Unlimited creation of Virtual cards</li>
                  <li>High credit limits with no interest</li>
                  <li>Expense management features</li>
                  <li>Cashbacks</li>
                  <li>Budgeting <span>Coming soon</span></li>
                  <li>Team cards [controlled by admin] <span>Coming soon</span></li>
                </ul>
              </div>

              <div data-aos="zoom-in">
                <p>Other business credit cards</p>
                <ul>
                  <li>All kind of fees (Annual / monthly, signup, etc.)</li>
                  <li>No virtual cards</li>
                  <li>Credit limits with interest</li>
                  <li>No expense management system</li>
                  <li>Cashbacks</li>
                  <li>No budgeting, expense tracking, Spend control</li>
                  <li>Limited number of supplementary cards per company</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div className="businessCard__faq">
          <div className="businessCard__faqContainer">
            <div className="businessCard__faq__topic">
              <p>Things to know</p>
              <div>
                <p>
                  Having trouble finding what you’re looking for? Contact our
                  team for immediate assistance via phone, chat, or email, or
                  consult the <a href="/">Help Center</a> at any time.
                </p>
                <a href="/contact-us">Contact us</a>
              </div>
            </div>
            <BusinessFaq />
          </div>
        </div>
      </div>
      <Scaling />
    </>
  );
};

export default BusinessCard;
