import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./components/ScrollToTop";
import AboutUs from "./pages/AboutUs";
import BusinessCard from "./pages/BusinessCard";
import ContactUs from "./pages/ContactUs";
import ExpenseManagement from "./pages/ExpenseManagement";
import LandingPage from "./pages/LandingPage";
import Pricing from "./pages/Pricing";
import Referral from "./pages/Referral";
import SpendManagement from "./pages/SpendManagement";
import VirtualCard from "./pages/VirtualCard";
import WorkWithUs from "./pages/WorkWithUs";
import { useEffect } from "react";
import PrivacyLayout from "./layouts/PrivacyLayout";
import Privacy from "./pages/Privacy/Privacy";
import WebLayout from "./layouts/WebLayout";
import WebLayout1 from "./layouts/WebLayout1";
import GeneralDisclosure from "./pages/Privacy/GeneralDisclosure";
import Prohibited from "./pages/Privacy/Prohibited";
import Platform from "./pages/Privacy/Platform";
import TermsAndConditions from "./pages/Privacy/TermsAndConditions";

function App() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route element={<WebLayout />}>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/spend-management" element={<SpendManagement />} />
            <Route path="/business-card" element={<BusinessCard />} />
            <Route path="/expense-management" element={<ExpenseManagement />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/work-with-us" element={<WorkWithUs />} />
          </Route>

          <Route element={<WebLayout1 />}>
            <Route path="/referral" element={<Referral />} />
            <Route path="/virtual-cards" element={<VirtualCard />} />
          </Route>

          <Route element={<PrivacyLayout />}>
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/general-disclosures" element={<GeneralDisclosure />} />
            <Route path="/prohibited-activities" element={<Prohibited />} />
            <Route path="/platform-agreement" element={<Platform />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
