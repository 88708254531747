import React from "react";
import "../scss/VirtualCards.scss";
import Card from "../assets/images/VirtualCard/card.svg";
import Control from "../assets/images/VirtualCard/control.png";
import Manage from "../assets/images/VirtualCard/manage.svg";
import Safer from "../assets/icons/VirtualCard/safer.svg";
import Single from "../assets/icons/VirtualCard/single.svg";
import Recurring from "../assets/icons/VirtualCard/recurring.svg";
import Lock from "../assets/icons/VirtualCard/lock.svg";
import Convenience from "../assets/icons/VirtualCard/convenience.svg";
import VirtualFaq from "../components/Faq/VirtualFaq";
import Scaling from "../components/Scaling";

const VirtualCard = () => {
  return (
    <>
    <div className="virtualCard">
      <div className="virtualCard__hero">
        <div className="virtualCard__hero1">
          <div className="virtualCard__hero2">
            <div className="virtualCard__hero__wrapper">
              <div className="virtualCard__hero__content">
                <h1 data-aos="fade-up">
                  Have complete control over where, when, and how much you can
                  be charged.
                </h1>
                <p data-aos="fade-right">
                  Create virtual cards for managing subscriptions and making
                  purchases online. With unique card numbers for every online
                  merchant, you’re better protected from fraud and over-charges.
                </p>
              </div>

              <img src={Card} alt="Card" data-aos="fade-left"/>
            </div>
          </div>
        </div>
      </div>

      <section className="virtualCard__control">
        <div className="virtualCard__control__wrapper">
          <h2 data-aos="zoom-in">
            Take control of your <br /> online payment.
          </h2>
          <div className="virtualCard__cardContainer">
            <div data-aos="zoom-in" data-aos-duration="2000">
              <img src={Safer} alt="Safer" />
              <h2>Safer Payment.</h2>
              <p>
                Protect your credit line & physical card details by creating and
                using virtual payment cards, instead of using your regular debit
                or credit card, for online purchases or payments
              </p>
            </div>

            <div data-aos="zoom-in" data-aos-duration="2500">
              <img src={Single} alt="single" />
              <h2>Single-use burner cards.</h2>
              <p>
                For a one-time online transaction, burner cards are ideal. It's
                a one-time-use virtual card that automatically expires after
                usage, thereby safeguarding your credit line from fraud and
                unwanted recurrent payments.
              </p>
            </div>

            <div data-aos="zoom-in" data-aos-duration="3000">
              <img src={Recurring} alt="Recurring" />
              <h2>Recurring subscription cards.</h2>
              <p>
                Recurring virtual cards are basically used for fixed and
                variable regular expenses. It automatically reloads its custom
                spend limit each month, which is usually used for
                subscription/recurring payments.
              </p>
            </div>

            <div data-aos="zoom-in" data-aos-duration="3500">
              <img src={Lock} alt="Lock" />
              <h2>Lock/unlock cards as needed.</h2>
              <p>
                You can simply lock, unlock or terminate a virtual card at any
                moment.
              </p>
            </div>

            <div data-aos="zoom-in" data-aos-duration="4000">
              <img src={Convenience} alt="Convenience" />
              <h2>Convenience of use. <span>Coming soon</span> </h2>
              <p>
                With our browser plugin, you can save time throughout the
                checkout process. With a single click, create and auto-fill
                secure card numbers at checkout.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="virtualCard__description">
        <div className="virtualCard__heroo">
          <div data-aos="zoom-in">
            <p>For your various online payments</p>
          </div>
          <h1 data-aos="zoom-in">
            Create as many virtual cards <br />
            as your business needs.
          </h1>
        </div>
        <div className="virtualCard__description__wrapper">
          <img src={Manage} alt="manage" data-aos="fade-right" />
          <div data-aos="fade-left">
            <h2>Manage Subscription Spend.</h2>
            <p>
              Issue vendor-specific cards, by having a card for every vendor
              your business uses or work with, and set a limit on the amount of
              money you may spend on it. Each month, the set card limits are
              updated/reset to ensure you don’t miss any vendor payments and
              also not overcharged.{" "}
            </p>
          </div>
        </div>
      </section>

      <section className="virtualCard__description">
        <div className="virtualCard__description__wrapper">
          <div data-aos="fade-up">
            <h2>Control Card Spend. <span>Coming soon</span> </h2>
            <p>
              Create cards for team members with predefined restrictions for any
              use case, using category and merchant level controls. Simply
              control spend before it happens, by restricting what team members
              (employees) spend on – down to specific categories and merchants –
              to prevent waste.{" "}
            </p>
          </div>
          <div className="virtualCard__description__image" data-aos="fade-down">
            <img src={Control} alt="manage" />
          </div>
        </div>
      </section>


      <div className="businessCard__faq">
        <div className="businessCard__faqContainer">
          <div className="businessCard__faq__topic">
            <p>Things to know</p>
            <div>
              <p>
                Having trouble finding what you’re looking for? Contact our team
                for immediate assistance via phone, chat, or email, or consult
                the <a href="/">Help Center</a>  at any time.
              </p>
              <a href="/contact-us">Contact us</a>
            </div>
          </div>
          <VirtualFaq />
        </div>
      </div>
    </div>

<Scaling />
</>
  );
};

export default VirtualCard;
