import React from 'react'
import '../scss/components/PrimaryButton.scss'

const PrimaryButton = ({text}) => {
  return (
    <div>
      <button className="primaryButton">
        {text}
      </button>
    </div>
  )
}

export default PrimaryButton