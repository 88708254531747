import React from "react";

const GeneralDisclosure = () => {
  return (
    <div className="privacyDashboard">
      <h1>General Disclosures</h1>‍<b>Updated April, 12 2022</b>
      <p>
        © 2022 Evea Inc. “Evea,” "Evea Financial" and the Evea logo are
        registered trademarks of the company | RC1886991. Evea products may not
        be available to all customers. Terms and conditions apply and are
        subject to change. The Evea Corporate Card is issued by Sudo, Licensed
        and Regulated by the Central Bank of Nigeria (CBN).
      </p>
      <p>
        Visit our <a href="/">Terms of Service</a> for details. Read our{" "}
        <a href="/privacy">Privacy Policy.</a>
      </p>
    </div>
  );
};

export default GeneralDisclosure;
