import React from "react";
import "../scss/components/Scaling.scss";
import { Link } from "react-router-dom";

const Scaling = () => {
  return (
    <div className="scaling">
      <div className="scaling__container">
        <h1>Start scaling faster with Evea</h1>
        <div>
          <button>Get Started</button>
          <Link to="/contact-us">
            <button>Talk to us</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Scaling;
