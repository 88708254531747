import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../scss/components/Faq.scss";

export default function ExpenseFaq() {
  return (
    <div>
      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is an expense management system?</Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <div>
              <p>
                An expense management system keeps track of each and every
                expense your company incurs. It basically monitors every
                expenditure made by the business.
              </p>
            </div>
          </>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            What is the significance of expenditure management?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              Expense management helps you to keep track of every money spent,
              regardless of where it was spent or who swiped the card. Giving
              you an increased visibility/insight into your expenses, thereby
              allowing you to make more informed decisions that help you achieve
              your company objectives faster.
            </p>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>
            {" "}
            When it comes to business expenses, how should you categorize them?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              From documenting company costs to using accounting software,
              categorization of expenses must be straightforward and easy for
              everyone to understand precisely where money is been spent. Food,
              office supplies, wages, rent, travel, etc. are all typical expense
              categories, but you'll need to shape them to your company's needs.
            </p>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>
            What is the difference between expense management & spend management
            (i.e. budgeting)?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <p>
              Budgeting is the guidance/road-map you draw for your company,
              outlining how much money will be allocated to each overhead;
              While, Expense management is the implementation of your budget.
              Expense management is the act of documenting and tracking all of
              your company's expenses, which can then be used to guide your
              budgeting process.
            </p>
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>
            What information should an expense report contain?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <>
            <div>
              <p>
                For an expense report, the following items should be included:
              </p>
              <ul>
                <li>Cardholder/Employee name</li>
                <li>Department </li>
                <li>Budget or expense category for the purchase</li>
                <li>Receipt of Item(s) purchased</li>
                <li>Date and time of the transaction</li>
                <li>How the transaction was paid</li>
                <li>Total amount spent</li>
                <li>Vendor </li>
                <li>Approvals</li>
              </ul>
            </div>
          </>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
