import React from "react";
import "../scss/SpendManagement.scss";
import Align from "../assets/icons/SpendManagement/align.svg";
import Analytical from "../assets/icons/SpendManagement/analytical.svg";
import Approve from "../assets/icons/SpendManagement/approve.svg";
import Control from "../assets/icons/SpendManagement/control.svg";
import Review from "../assets/icons/SpendManagement/review.svg";
import Track from "../assets/icons/SpendManagement/track.svg";
import Controll from "../assets/images/SpendManagement/control.svg";
import Spending from "../assets/images/SpendManagement/spending.svg";
import SendandReq from "../assets/images/SpendManagement/sendandreq.svg";
import Faq from "../components/Faq/SpendFaq";
import Scaling from "../components/Scaling";

const SpendManagement = () => {
  return (
    <>
      <div className="spend-management">
        <div className="spend-management__wrapper">
          <div className="spend-management__container">
            <h1 data-aos="zoom-in">
              Manage and control your business spending effortlessly, using
              budget. <span>Coming soon</span>
            </h1>
            <p>
              Unlock the cost-effective, <br /> money & time-saving budgeting
              software, <br />
              that helps you and your team spend smarter.
            </p>
          </div>
        </div>

        <section className="spend-management__control">
          <div className="spend-management__control__wrapper">
            <h2>
              Take control of your <br /> online payment.
            </h2>
            <div className="spend-management__cardContainer">
              <div data-aos="zoom-in">
                <img src={Align} alt="Align" />
                <h2>Align budgets to your business</h2>
                <p>
                  Create budgets by department, team, or project, and then add
                  or remove team members (personnel) as needed.
                </p>
              </div>

              <div data-aos="zoom-in">
                <img src={Track} alt="Track" />
                <h2>Track spending, in real-time</h2>
                <p>
                  You can always compare current spend against budgets to see
                  where your business stands, at any given time.
                </p>
              </div>

              <div data-aos="zoom-in">
                <img src={Review} alt="Review" />
                <h2>Review & forecast budgets by period</h2>
                <p>
                  Review previous months spending and make adjustments to
                  budgets for subsequent months based on budget periods.
                </p>
              </div>

              <div data-aos="zoom-in">
                <img src={Control} alt="Control" />
                <h2>Control budgets by team</h2>
                <p>
                  Allow managers & team leaders to manage their own budgets by
                  assigning rights.
                </p>
              </div>

              <div data-aos="zoom-in">
                <img src={Approve} alt="Approve" />
                <h2>Approve or deny fund request.</h2>
                <p>
                  From a push notification, budget owners (i.e. team
                  leaders/managers) or admins can accept or refuse fund requests
                  from their team members.
                </p>
              </div>

              <div data-aos="zoom-in">
                <img src={Analytical} alt="Analytical" />
                <h2>Analytical data/reports</h2>
                <p>
                  Admins and budget owners get deeper insight into the expense
                  data by department, team, project, or individual budget.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="spend-management__bg1">
          <div className="spend-management__masterHero">
            <div className="spend-management__heroo">
              <div data-aos="zoom-in">
                <p>Have control over your business finances.</p>
              </div>
              <h1 data-aos="zoom-in">
                Be in control over your <br />
                funds, by spending smarter.
              </h1>
            </div>
            <section className="spend-management__description">
              <div className="spend-management__description__wrapper">
                <img src={Controll} alt="sendandreq" data-aos="fade-right" />
                <div data-aos="fade-left">
                  <h2>
                    Control spend before it happens, and work together as a team
                    using the budgeting software.
                  </h2>
                  <p>
                    Budgets in Evea is a formidable tool for controlling spend. Set spending limits(within the budget) in Evea that are directly linked to employee cards. That's all.
                  </p>
                </div>
              </div>
            </section>

            <section className="spend-management__description">
              <div className="spend-management__description__wrapper">
                <div data-aos="fade-right">
                  <h2>
                    Spending is completely transparent, full insight of spend.
                  </h2>
                  <p>
                    In real time, you can now know where every dollar is and
                    where it is going. The budgeting software syncs
                    automatically with all cards issued, so you don't have to
                    rely on outdated figures in an unvarying spreadsheet to
                    create your budgeting plan. With real-time insight into
                    spend by department, team, project, expense type (category)
                    or individual budget, there will be no more overspending.
                  </p>
                </div>
                <img src={Spending} alt="spending" data-aos="fade-left" />
              </div>
            </section>

            <section className="spend-management__description">
              <div className="spend-management__description__wrapper">
                <img src={SendandReq} alt="sendandreq" data-aos="fade-right" />
                <div data-aos="fade-left">
                  <h2>Send and request funds between team members.</h2>
                  <p>
                    Sending or requesting funds has never been easier. Team
                    member(s)/employees assigned to a specific budget can
                    request for spend limit; and the admins or budget owners
                    (i.e. team leaders) can authorize/approve such request and
                    send funds to the employee. Once sent, those funds are
                    instantly available on the employee’s Evea card. No worries,
                    you are in complete control of your business finances.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="businessCard__faq">
          <div className="businessCard__faqContainer">
            <div className="businessCard__faq__topic">
              <p>Frequently asked questions</p>
              <div>
                <p>
                  Having trouble finding what you’re looking for? Contact our
                  team for immediate assistance via phone, chat, or email, or
                  consult the <a href="/">Help Center</a> at any time.
                </p>
                <a href="/contact-us">Contact us</a>
              </div>
            </div>
            <Faq />
          </div>
        </div>
      </div>
      <Scaling />
    </>
  );
};

export default SpendManagement;
